import { useCallback, useEffect, useState } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { ChakraProvider, Spinner } from '@chakra-ui/react'
import { ThemeProvider } from '@emotion/react'
import { AppStore, keycloak, OperationConfig, RemoteOperation, Router as StyleWhereRouter } from 'stylewhere/shared'
import { Box, ConfirmModal, Modal, FeedbackModal } from 'stylewhere/components'
import { ModalType } from 'stylewhere/types'
import { theme, chakraTheme } from 'stylewhere/theme'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { Routes } from 'stylewhere/pages'
import 'react-virtualized/styles.css'

const App: React.FC = () => {
  const [loading, setloading] = useState<boolean>(true)
  const [showConfirm, setshowConfirm] = useState<boolean>(false)
  const [showFeedback, setshowFeedback] = useState<boolean>(false)
  const [modals, setmodals] = useState<ModalType[]>([])
  const [authenticated, setauthenticated] = useState<boolean>(false)
  const [, updateState] = useState<any>()
  const [operations, setOperations] = useState<OperationConfig[]>()
  const forceUpdate = useCallback(() => updateState({}), [])
  let appStoreLoaded = false

  let expirationTimeout: NodeJS.Timeout | null = null

  // Workaround per hot reload
  if (operations !== undefined) {
    RemoteOperation.operations = operations
  }

  useEffect(() => {
    async function init() {
      AppStore.toggleConfirmModalHandler = (show) => setshowConfirm(show)
      AppStore.toggleFeebackModalHandler = (show) => setshowFeedback(show)
      AppStore.openModal = (modal: ModalType) => setmodals([...modals, modal])
      AppStore.closeModal = (id: string) => {
        const modalIndex = modals.findIndex((modal) => modal.id === id)
        if (modalIndex === -1) return
        modals[modalIndex].visible = false
        setmodals(modals.filter((m) => m.visible))
      }
      AppStore.reloadRouting = () => forceUpdate()
    }

    init()
    return () => {}
  }, [forceUpdate, modals])

  const onEventKeycloak = async (eventType, error) => {
    !!expirationTimeout && clearTimeout(expirationTimeout)
    expirationTimeout = null

    AppStore.keycloakEvent = { eventType, error }
    const loggedUser = ['onReady', 'onAuthSuccess'].includes(eventType)
    if (loggedUser && !appStoreLoaded) {
      appStoreLoaded = true
      await AppStore.loadInitialData()
      setauthenticated(loggedUser)
      setloading(false)
      setOperations(RemoteOperation.operations)
    }
    if (eventType === 'onAuthRefreshSuccess') {
      AppStore.loadAuthToken()
    } else if (eventType === 'onTokenExpired') {
    } else if (!loggedUser) {
      expirationTimeout = setTimeout(() => {
        setauthenticated(loggedUser)
      }, 5000)
    }
  }

  const paths = Object.keys(Routes)
  const activeRoutes = paths.filter((path) =>
    authenticated ? !Routes[path].public || Routes[path].private : Routes[path].public
  )
  return (
    <ReactKeycloakProvider
      initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
      onEvent={onEventKeycloak}
      authClient={keycloak}
    >
      <ChakraProvider theme={chakraTheme}>
        <ThemeProvider theme={theme}>
          {loading && (
            <Box flex center vcenter>
              <Spinner thickness="5px" speed="0.65s" color="#e0e0e0" size="xl" />
            </Box>
          )}

          {!loading && (
            <>
              <Router history={StyleWhereRouter.history}>
                <Switch>
                  {activeRoutes.map((path) => (
                    <Route path={path} component={Routes[path].component} key={path} exact />
                  ))}
                  <Redirect to={authenticated ? '/' : '/login'} />
                </Switch>
              </Router>

              {modals.map((modal) => {
                if ('modal' in modal) return modal.modal

                return (
                  <Modal
                    {...modal}
                    key={modal.id}
                    onClose={() => {
                      modal.visible = false
                      setmodals(modals.filter((m) => m.visible))
                      modal?.onClose?.()
                    }}
                  >
                    {typeof modal.body === 'function' ? modal.body() : modal.body}
                  </Modal>
                )
              })}

              {showConfirm && (
                <ConfirmModal
                  title={AppStore.confirmModalParams.title}
                  subtitle={AppStore.confirmModalParams.message}
                  onClose={() => {
                    AppStore.confirmModalParams?.onCancel?.()
                    setshowConfirm(false)
                  }}
                  onConfirm={() => {
                    AppStore.confirmModalParams?.onConfirm()
                    setshowConfirm(false)
                  }}
                  labelClose={AppStore.confirmModalParams.labelClose}
                  labelConfirm={AppStore.confirmModalParams.labelConfirm}
                  showDetailForProduct={AppStore.confirmModalParams.showDetailForProduct}
                />
              )}
              {showFeedback && (
                <FeedbackModal
                  title={AppStore.feedbackModalParam.title}
                  subtitle={AppStore.feedbackModalParam.message}
                  onClose={() => {
                    AppStore.feedbackModalParam?.onCancel?.()
                    setshowFeedback(false)
                  }}
                  btnCloseModal={AppStore.feedbackModalParam.btnCloseModal}
                />
              )}
            </>
          )}
        </ThemeProvider>
      </ChakraProvider>
    </ReactKeycloakProvider>
  )
}

export default App
