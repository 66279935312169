import { Component } from 'react'
import { Box, Select, Text } from 'stylewhere/components'
import styled from '@emotion/styled'
import { T, __ } from 'stylewhere/i18n'

interface Props {
  zones: any[]
  selected: string
  onChange: (value: any) => void
}

export class SortingHeader extends Component<Props> {
  getSelectedZone = () => {
    const { zones, selected } = this.props
    const obj = zones.find((el) => el.id === selected)
    return obj.description || '---'
  }

  render() {
    const { zones, onChange, selected } = this.props
    return (
      <Container row>
        <Box mr={20} textAlign="right">
          <Text fontSize={16} bold>
            {__(T.misc.selected_zone)}
          </Text>
          <Text fontSize={18} style={{ marginTop: 3 }}>
            {this.getSelectedZone()}
          </Text>
        </Box>
        {zones.length > 1 && (
          <Select
            onSelect={onChange}
            placeholder={<span>{__(T.misc.change)}</span>}
            options={zones}
            config={{ value: 'id', label: 'description' }}
            defaultValue={selected}
            multiple={false}
            value={selected}
            useButton
            disabled={zones.length === 1}
          />
        )}
      </Container>
    )
  }
}

const Container = styled(Box)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
