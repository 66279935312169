import { Component } from 'react'
import { OnSelectAsnCodeParams, OperationStart, ShippingByAsnStartInput, ShippingByAsnStartListAndInput } from 'stylewhere/components'
import { Router, getDataFromSchema, ShippingOperationConfig, RemoteOperation, RfidReader, OperationReadingProvider, OperationReadingState } from 'stylewhere/shared'
import { ShippingExtensions } from 'stylewhere/extensions'
import { showToastError, isModalError } from 'stylewhere/utils'
import { Shippings, Asns, TmrTag, Asn, DecodedItem, TagInfoItem } from 'stylewhere/api'
import type { Routes } from 'stylewhere/pages'
import { RouteComponentProps, StaticContext } from 'react-router'
import { T, __ } from 'stylewhere/i18n'
import { ShippingParcelStartParams } from './ShippingParcelStart'

interface Params {
  opCode: string
}

interface State {
  skipAsnSelection?: boolean
  items: DecodedItem<string>[]
}


const USE_BATCH_READING = true

export default class ShippingStart extends Component<RouteComponentProps<Params, StaticContext, OperationReadingState>> {
  submitPath: Routes = '/shipping/:opCode/reading'
  asnParcelPath: Routes = '/shipping/:opCode/asn/:asnId'
  selfPath: Routes = '/shipping/:opCode'
  operation = RemoteOperation.getOperationConfig<ShippingOperationConfig>(Router.getMatchParams(this.props).opCode)
  checkingIdentifier = false
  isModal = false

  state: State = {
    items: [],
  }
  locationState = Router.getLocationState(this.props)

  mustSelectAsn() {
    return this.operation && ['INBOUND', 'OUTBOUND'].includes(this.operation.type) && this.operation.shippingMode === 'shipment' && this.operation.startAsnScreen !== 'no' && !this.state.skipAsnSelection
  }

  setSkipAsnSelection = () => {
    this.setState({ skipAsnSelection: false })
  }

  isAsnSelectMode() {
    return [
      "inputCode",
      "asnList",
      "asnListAndInputCode"
    ].includes(this.operation?.startAsnScreen)
  }

  async componentDidMount() {
    this.isModal = isModalError(this.operation)
    if (this.operation.startWithRfidSearch) {
      if (USE_BATCH_READING) {
        RfidReader.setBatchInterval(this.operation.batchInterval)
        RfidReader.setBatchIntervalTagCount(this.operation.batchIntervalTagCount)
        RfidReader.setBatchIntervalTime(this.operation.batchIntervalTime)
        RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
        RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
        await OperationReadingProvider.init(this.operation, this.locationState, this.goBack, this.setRfidReaderDecode, true)

      } else {
        RfidReader.initialize()
        RfidReader.onTagReadCallback = this.onTagReadCallback
      }
    }
  }

  goBack = () => {
    if (this.isAsnSelectMode()) {
      this.setSkipAsnSelection()
    } else {
      Router.navigate('/')
    }
  }

  setRfidReaderDecode = () => {
    RfidReader.setDecodeFunction(async (epcs) => {
      return epcs.reduce((acc, epc) => {
        acc[epc] = { epc }
        return acc
      }, {})
    })
    RfidReader.setOnDecodedItemCallback(this.onDecodedItemCallback, this.getDecodeRequest())
  }

  getDecodeRequest = () => {
    return {
      url: Shippings.batchValidateEndpoint(this.operation),
      payload: {
        operationId: this.operation.id,
      },
    }
  }
  
  onDecodedItemCallback = async (itemMapFromReader: { [tagCode: string]: DecodedItem }) => {
    if (this.checkingIdentifier) return
    this.checkingIdentifier = true
    RfidReader.stop()
    RfidReader.clear()
    try {
      const tags = Object.keys(itemMapFromReader).map((code) => ({ epc: code }))
      const firstTag = tags[0]
      const parcelByIdentifier = await Shippings.parcelByIdentifier(this.operation, firstTag?.epc)
      const parcel = await Shippings.startParcel(this.operation, {
        parcelCode: parcelByIdentifier?.code || '',
        attributes: parcelByIdentifier?.attributes || {},
        operationId: this.operation.id,
        asn: parcelByIdentifier?.asn || {},
      })
      const formData: Record<string, unknown> = {}
      const state: Record<string, any> = { formData, parcel, tags }
      if (this.isAsnSelectMode()) {
        state.formData.parcelCode = parcelByIdentifier?.code
        // formData.attributes = parcelByIdentifier?.attributes
        state.asn = parcelByIdentifier?.asn
      }
      Router.navigate(
        this.submitPath,
        { opCode: this.operation.code },
        { state }
      )
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
    }
    this.checkingIdentifier = false
  }

  // @deprecated  - use onDecodedItemCallback
  onTagReadCallback = async (tag: TmrTag) => {
    if (this.checkingIdentifier) return
    this.checkingIdentifier = true
    RfidReader.stop()
    RfidReader.clear()
    try {
      const parcelByIdentifier = await Shippings.parcelByIdentifier(this.operation, tag.epc)
      const parcel = await Shippings.startParcel(this.operation, {
        parcelCode: parcelByIdentifier?.code || '',
        attributes: parcelByIdentifier?.attributes || {},
        operationId: this.operation.id,
        asn: parcelByIdentifier?.asn || {},
      })
      const formData: Record<string, unknown> = {}
      const state: Record<string, any> = { formData, parcel, tags: [tag] }
      if (this.isAsnSelectMode()) {
        state.formData.parcelCode = parcelByIdentifier?.code
        // formData.attributes = parcelByIdentifier?.attributes
        state.asn = parcelByIdentifier?.asn
      }
      Router.navigate(
        this.submitPath,
        { opCode: this.operation.code },
        { state }
      )
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
    }
    this.checkingIdentifier = false
  }

  onSubmit = async (formData, operation: ShippingOperationConfig, schema) => {
    try {
      if (operation.hasChecklist) {
        const payloadStartParcel: any = getDataFromSchema(formData, schema)
        const parcel = await Shippings.startParcel(operation, payloadStartParcel)
        Router.navigate(this.submitPath, { opCode: operation.code }, { state: { formData, parcel } })
      }
      Router.navigate(this.submitPath, { opCode: operation.code }, { state: { formData } })
    } catch (err) {
      showToastError(err, 'Start Parcel Error', this.isModal)
    }
  }

  onSelectAsnCode = async (params: OnSelectAsnCodeParams) => {
    try {
      const { selectedAsn, currentAsnCode } = params;

      let asnId = selectedAsn?.id;

      if (!asnId) {
        if (!currentAsnCode) {
          throw new Error(__(T.error.no_asn_selected))
        }
        const searchedAsnsResult = await Asns.search<Asn>({ equalCodes: [currentAsnCode], size: 1 })
        if (searchedAsnsResult.content.length > 0) {
          asnId = searchedAsnsResult.content[0].id // found
        }
        if (!asnId) {
          const newAsn = await Shippings.startAsn(this.operation, currentAsnCode) // create
          asnId = newAsn.id
        }
        if (!asnId) {
          throw new Error(__(T.error.submit_asn))
        }
      }

      const nextRouteParams: ShippingParcelStartParams = {
        opCode: this.operation.code,
        asnId
      }
      Router.navigate(this.asnParcelPath, nextRouteParams)
    }
    catch (err) {
      showToastError(err, 'Start ASN Error', this.isModal)
    }
  }
  onReadParcel = () => {
    this.setState({ skipAsnSelection: true })
  }

  render() {
    if (this.mustSelectAsn()) {
      switch (this.operation.startAsnScreen) {
        case "inputCode":
          return <ShippingByAsnStartInput
            operation={this.operation}
            onSelectAsnCode={this.onSelectAsnCode}
          />
        case "asnList":
        case "asnListAndInputCode":
          return <ShippingByAsnStartListAndInput
            operation={this.operation}
            onSelectAsnCode={this.onSelectAsnCode}
            onClickReadParcel={this.onReadParcel}
          />
      }
    }

    const onBackPress = this.isAsnSelectMode() ?
      this.setSkipAsnSelection :
      undefined

    return (
      <OperationStart
        onBackPress={onBackPress}
        startWithRfidSearch={this.operation.startWithRfidSearch}
        submitPath={this.submitPath}
        extensions={ShippingExtensions}
        onSubmit={this.onSubmit}
        onTagReadCallback={!USE_BATCH_READING && this.operation.startWithRfidSearch ? this.onTagReadCallback : undefined}
        setOnDecodedItemCallback={USE_BATCH_READING && this.operation.startWithRfidSearch ? this.setRfidReaderDecode : undefined}
      />
    )
  }
}
