import { forwardRef } from 'react'
import styled from '@emotion/styled'
import { get as _get, set as _set } from 'lodash'
import { Icons, Input, StyledInput, Select, Box, DatePicker, Switch, TextArea } from 'stylewhere/components'
import {
  AutocompleteFormSchemaField,
  CheckboxFormSchemaField,
  DateFormSchemaField,
  FormSchemaField,
  NumberFormSchemaField,
  SelectFormSchemaField,
  TextFormSchemaField,
  HiddenFormSchemaField,
  setArgsParams,
  addEnabledField,
  OperationConfig,
  FormSchemaData,
  AppStore,
} from 'stylewhere/shared'
import { api, BaseResource, ResourcePage } from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'

interface Props<Value = any> {
  disabled?: boolean
  field: FormSchemaField
  index: number
  value: Value
  defaultValue: Value
  onChange: (value: any) => void
  onEnter?: (value: any) => void
  operation?: OperationConfig
  formData?: FormSchemaData
}

const LabelDiv = styled.div`
  position: relative;
`

const Label = styled.label`
  font-weight: 600;
  font-size: 10pt;
  position: absolute;
  top: 8px;
  left: 15px;
  z-index: 10;
`

export const FormSchemaFieldLabel: React.FC<{ label: string; required?: boolean }> = ({ label, required }) => {
  if (required)
    return (
      <span>
        {label} <sup>*</sup>
      </span>
    )
  return <span>{label}</span>
}

export const FormSchemaTextareaField: React.FC<Props> = ({ index, field, defaultValue, value, onChange, disabled }) => {
  return (
    <LabelDiv>
      <Label key={`label${index.toString()}`} htmlFor={field.name}>
        <FormSchemaFieldLabel label={field.label} required={field.required} />
      </Label>
      <TextArea
        key={`input${index.toString()}`}
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
        required={field.required}
        id={field.name}
        disabled={disabled ?? field.disabled}
      />
    </LabelDiv>
  )
}

const CheckboxWrapper = styled(Box)`
  height: 65px;
  background: #ffffff;
  border: 2px solid #d2d2d2;
  border-radius: 10px;
  padding-left: 22px;
  padding-right: 22px;
  margin-top: 20px;

  :disabled {
    border: 2px solid transparent;
    color: #000000;
  }
`

export const FormSchemaCheckboxField: React.FC<
  Props & {
    field: CheckboxFormSchemaField
  }
> = ({ index, field, defaultValue, value, onChange, disabled }) => {
  return (
    <CheckboxWrapper key={index.toString()} row>
      <Switch
        onChange={(checked) => {
          if (field.onValue !== undefined && checked) {
            onChange(field.onValue)
          } else if (field.offValue !== undefined && !checked) {
            onChange(field.offValue)
          } else {
            onChange(checked)
          }
        }}
        check={value ?? defaultValue}
        id={field.name}
        name={field.name}
        label={field.label}
        labelPosition="right"
        wrapperProps={{
          justify: 'space-between',
          width: '100%',
        }}
        disabled={disabled ?? field.disabled}
      />
    </CheckboxWrapper>
  )
}

export const FormSchemaDateField: React.FC<
  Props & {
    field: DateFormSchemaField
  }
> = ({ index, field, defaultValue, value, onChange, disabled }) => {
  const dateValue = value === undefined ? defaultValue : value

  let startDate
  let endDate
  let selected
  let DateInput
  let DateInputStart
  let DateInputEnd
  if (field.range) {
    startDate = dateValue && dateValue[field.startDatePath] && new Date(dateValue[field.startDatePath])
    endDate = dateValue && dateValue[field.endDatePath] && new Date(dateValue[field.endDatePath])
    DateInputStart = forwardRef<HTMLInputElement>((props, ref) => (
      <StyledInput label={startDate} error={false} {...props} ref={ref} />
    ))
    DateInputEnd = forwardRef<HTMLInputElement>((props, ref) => (
      <StyledInput label={endDate} error={false} {...props} ref={ref} />
    ))
  } else {
    selected = dateValue && new Date(dateValue)
    DateInput = forwardRef<HTMLInputElement>((props, ref) => (
      <StyledInput label={selected} error={false} {...props} ref={ref} />
    ))
  }

  let placeholderText
  if (!selected) {
    placeholderText = field.required ? `${field.label} *` : field.label
  }
  return (
    <LabelDiv>
      {selected && (
        <Label key={`label${index.toString()}`} htmlFor={field.name}>
          <FormSchemaFieldLabel label={field.label} required={field.required} />
        </Label>
      )}
      {!field.range && (
        <DatePicker
          name={field.name}
          dateFormat={field.dateFormat}
          selected={selected}
          showTimeSelect={field.time}
          onChange={(date: Date | null) => {
            const newDate = date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : null
            if (field.useTimezone) {
              if (newDate) {
                newDate.setUTCHours(8, 0, 0, 0)
                const hourTimezoneOffset = newDate.getTimezoneOffset() / 60

                const time = hourTimezoneOffset.toString().split('.')
                const hour = parseInt(time[0], 10)
                const negative = hour < 0 ? true : false

                let hourStr = Math.abs(hour) < 10 ? '0' + Math.abs(hour) : Math.abs(hour)
                hourStr = negative ? '+' + hourStr : '-' + hourStr

                const timezone = time[1] ? hourStr + ':' + (parseInt(time[1], 10) * 6).toString() : hourStr + ':00'
                const string = newDate.toISOString().replace('.000Z', timezone)
                onChange(string)
              } else {
                onChange(newDate)
              }
            } else {
              if (newDate && date) {
                newDate.setUTCHours(1, 0, 0, 0)
                if (newDate.getDate() !== date.getDate()) {
                  newDate.setDate(newDate.getDate() + 1)
                }
              }
              onChange(newDate && newDate.toISOString())
            }
          }}
          required={field.required}
          isClearable
          disabled={disabled ?? field.disabled}
          customInput={<DateInput />}
          placeholderText={placeholderText}
          showYearDropdown
        />
      )}
      {field.range && (
        <Box flex row>
          <Box mr={5} style={{ flexShrink: 1 }}>
            <DatePicker
              dateFormat={field.dateFormat}
              name={`${field.name}-start`}
              placeholderText={__(T.misc.from)}
              showTimeSelect={field.time}
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={(date: Date | null) =>
                onChange({
                  [field.startDatePath]: date && date.toISOString(),
                  [field.endDatePath]: endDate,
                })
              }
              required={field.required}
              disabled={disabled ?? field.disabled}
              isClearable
              customInput={<DateInputStart />}
            />
          </Box>
          <Box ml={5} style={{ flexShrink: 1 }}>
            <DatePicker
              dateFormat={field.dateFormat}
              name={`${field.name}-end`}
              placeholderText={__(T.misc.to)}
              showTimeSelect={field.time}
              selected={endDate}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              onChange={(date: Date | null) =>
                onChange({
                  [field.startDatePath]: startDate,
                  [field.endDatePath]: date && date.toISOString(),
                })
              }
              required={field.required}
              disabled={disabled ?? field.disabled}
              isClearable
              customInput={<DateInputEnd />}
            />
          </Box>
        </Box>
      )}
    </LabelDiv>
  )
}

export const FormSchemaAutocompleteField: React.FC<
  Props & {
    field: AutocompleteFormSchemaField
    formContext?: string
  }
> = ({ index, field, defaultValue, value, onChange, disabled, formContext, operation, formData }) => {
  const { endpoint } = field

  const asyncOptions = (search: string, callback: (results: any[]) => void) => {
    ;(async () => {
      search = search.trim()
      // Usiamo il callback invece di restituire la promise sennò react-select non si aggiorna corretamente
      if (field && field.action) {
        if (operation && field.endpoint) {
          const actionEndpoint = field.endpoint.replace('{operationId}', operation.id)
          const payload: any = {
            size: 25,
            actionCode: field.action.code,
            attributes: {},
          }
          if (search) {
            payload.attributes.search = search
          }
          if (field.action.payload.operationId) {
            payload.operationId = operation.id
          }
          if (field.action.payload.attributes) {
            let attrVal = ''
            field.action.payload.attributes.map((attr) => {
              attrVal = ''
              if (attr.value) {
                attrVal = attr.value
              } else if (attr.dynamicValue) {
                if (attr.dynamicValue === ':placeId' && AppStore.defaultWorkstation) {
                  attrVal = AppStore.defaultWorkstation.placeId
                } else {
                  const _val = _get(formData, attr.dynamicValue)
                  if (_val) {
                    attrVal = _val
                  }
                }
              }
              if (attrVal !== '') {
                _set(payload.attributes, attr.field, attrVal)
              }
            })
          }
          try {
            const res = await api.post<ResourcePage<any>>(actionEndpoint, payload)
            callback((res.data as any) ?? [])
          } catch (e) {
            callback([])
          }
        } else {
          callback([])
        }
      } else {
        const params: any = search ? { search } : { size: 25 }
        if (field.dynamicParams) {
          let val
          for (let d = 0; d < field.dynamicParams.length; d++) {
            val = _get(operation, field.dynamicParams[d].value)
            if (val) params[field.dynamicParams[d].field] = val
          }
        }

        const result = (await api.get<ResourcePage<BaseResource> | BaseResource[]>(addEnabledField(setArgsParams(endpoint)), params))
          .data
        if (result !== undefined && 'content' in result) {
          // Risultato paginato
          callback(result.content)
        } else if (result !== undefined && Array.isArray(result)) {
          // Risultato non paginato
          callback(result)
        } else {
          // Nessun risultato
          callback([])
        }
      }
    })()
  }

  if (field.readOnly === true || (formContext !== undefined && field.readOnly === formContext)) {
    return (
      <FormSchemaValueField
        index={index}
        field={field}
        defaultValue={defaultValue?.description ?? defaultValue?.code ?? ''}
        formContext={formContext}
      />
    )
  }
  return (
    <LabelDiv>
      {value && (
        <Label key={`label${index.toString()}`} htmlFor={field.name}>
          <FormSchemaFieldLabel label={field.label} required={field.required} />
        </Label>
      )}
      <Select
        onSelect={onChange}
        placeholder={<FormSchemaFieldLabel label={field.label} required={field.required} />}
        asyncOptions={asyncOptions}
        config={{
          value: 'id',
          label: field.optionLabel ?? 'description',
          secondaryLabel: field.optionSecondaryLabel ?? 'code',
          concatSecondaryLabel: field.concatSecondaryLabel,
        }}
        multiple={field.multiple}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled ?? field.disabled}
        searchDisabled={field.searchDisabled}
      />
    </LabelDiv>
  )
}

export const FormSchemaSelectField: React.FC<
  Props & {
    field: SelectFormSchemaField
  }
> = ({ index, field, defaultValue, value, onChange, disabled }) => {
  return (
    <LabelDiv>
      {value && (
        <Label key={`label${index.toString()}`} htmlFor={field.name}>
          <FormSchemaFieldLabel label={field.label} required={field.required} />
        </Label>
      )}
      <Select
        onSelect={onChange}
        placeholder={<FormSchemaFieldLabel label={field.label} required={field.required} />}
        options={field.options}
        config={{ value: 'value', label: 'label' }}
        defaultValue={defaultValue}
        multiple={field.multiple}
        value={value}
        disabled={disabled ?? field.disabled}
      />
    </LabelDiv>
  )
}

export const FormSchemaTextField: React.FC<
  Props & {
    field: TextFormSchemaField
    formContext?: string
  }
> = ({ index, field, defaultValue, value, formContext, onChange, onEnter, disabled }) => {
  const Icon = field.icon && Icons[field.icon]
  const label = field.required ? `${field.label} *` : field.label
  const readOnly = field.readOnly === true || (formContext !== undefined && field.readOnly === formContext)

  return (
    <LabelDiv>
      <Input
        key={`input${index.toString()}`}
        defaultValue={defaultValue}
        required={field.required ?? false}
        onChange={onChange}
        currentValue={value}
        id={field.name}
        type={field.type}
        placeholder={field.secondaryLabel}
        startFocus={field.focus}
        image={Icon && <Icon width={field.iconWidth} height={field.iconHeight} />}
        label={label}
        disabled={disabled ?? field.disabled}
        minLength={field.minlength}
        maxLength={field.maxlength}
        autoFocus={field.autoFocus}
        showClear={!readOnly}
        readOnly={readOnly}
        onEnter={onEnter}
      />
    </LabelDiv>
  )
}

export const FormSchemaNumberField: React.FC<
  Props & {
    field: NumberFormSchemaField
  }
> = ({ index, field, defaultValue, value, onChange, disabled }) => {
  const Icon = field.icon && Icons[field.icon]
  return (
    <LabelDiv>
      <Input
        key={`input${index.toString()}`}
        defaultValue={defaultValue}
        required={field.required ?? false}
        onChange={(itm) => onChange(itm)}
        currentValue={value}
        id={field.name}
        type="number"
        placeholder={field.secondaryLabel}
        startFocus={field.focus}
        image={Icon && <Icon />}
        label={<FormSchemaFieldLabel label={field.label} required={field.required} />}
        disabled={disabled ?? field.disabled}
        min={field.min?.toString()}
        max={field.max?.toString()}
        step={field.step?.toString()}
        autoFocus={field.autoFocus}
      />
    </LabelDiv>
  )
}

export const FormSchemaValueField: React.FC<
  Omit<Props, 'onChange' | 'value'> & {
    field: FormSchemaField
    formContext?: string
  }
> = ({ index, field, defaultValue, formContext }) => {
  return (
    <LabelDiv>
      <Input
        key={`input${index.toString()}`}
        currentValue={defaultValue}
        defaultValue={defaultValue}
        id={field.name}
        type="text"
        placeholder={field.secondaryLabel}
        label={field.label}
        readOnly={field.readOnly === true || (formContext !== undefined && field.readOnly === formContext)}
        showClear={false}
      />
    </LabelDiv>
  )
}

export const FormSchemaHiddenField: React.FC<
  Props & {
    field: HiddenFormSchemaField
  }
> = ({ index, field, defaultValue, value }) => {
  return (
    <LabelDiv>
      <Input
        key={`input${index.toString()}`}
        defaultValue={defaultValue}
        currentValue={value}
        id={field.name}
        type={field.type}
      />
    </LabelDiv>
  )
}

/**
 * Example
 * schema={[
        { placeholder: __(T.misc.username), name: 'username', required: true },
        { placeholder: __(T.misc.password), name: 'password', type: 'password', required: true },
    ]}
 */
export const FormSchemaFormField: React.FC<
  Props & {
    formContext?: string
  }
> = ({ field, ...props }) => {
  if (field.customRender) {
    return field.customRender({ field, ...props })
  }
  switch (field.type) {
    case 'textarea':
      return <FormSchemaTextareaField field={field} {...props} />
    case 'checkbox':
      return <FormSchemaCheckboxField field={field} {...props} />
    case 'date':
      return <FormSchemaDateField field={field} {...props} />
    case 'autocomplete':
      return <FormSchemaAutocompleteField field={field} {...props} />
    case 'select':
      return <FormSchemaSelectField field={field} {...props} />
    case 'text':
    case 'password':
      return <FormSchemaTextField field={field} {...props} />
    case 'number':
      return <FormSchemaNumberField field={field} {...props} />
    case 'value':
      return <FormSchemaValueField field={field} {...props} />
    case 'hidden':
      return <FormSchemaHiddenField field={field} {...props} />
    default:
      return null
  }
}
