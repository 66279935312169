import React, { Component } from 'react'
import styled from '@emotion/styled'
import {
  Page,
  Box,
  FullLoadingLayer,
  TagCounter,
  OperationReadingList,
  ToggleField,
  Input,
  Icons,
  EncodingIdentifers,
  InputModal,
  EncodingDailyItems,
} from 'stylewhere/components'
import {
  AppStore,
  Router,
  RemoteOperation,
  OperationReadingProps,
  OperationReadingState,
  RfidReader,
  Sounds,
} from 'stylewhere/shared'
import { EncodingOperationConfig } from 'stylewhere/shared/RemoteOperation'
import { EncodingExtensions } from 'stylewhere/extensions'
import { Encodings, EncodingValidationRequest, EncodingValidationResponse, TmrTag, TmrItem } from 'stylewhere/api'
import {
  showToast,
  showToastError,
  askUserConfirmation,
  BLOCKED_ERRORS,
  MAX_WRITE_ATTEMPT,
  getInitialType,
  encodingComputeTagsEntityCode,
  getEncodingAssociationStatus,
  getCanForceAssocation,
  getUhfTagToWriteRegex,
  getEnableUhfWrite,
} from 'stylewhere/shared/utils'
import { T, __, __UP } from 'stylewhere/i18n'

interface State extends OperationReadingState {
  externalId: string
  processing: boolean
  parcelCode: string
  parcelCodeResetCounter: number
  parcelCodeVerified: boolean
  deleteItems: boolean
  wam: string
  wamVerified: boolean
  wamResetCounter: number
  antennaOn: boolean
  encodingValidation?: EncodingValidationRequest
  encodingValidationResponse?: EncodingValidationResponse
  encodingCreateResponse?: EncodingValidationResponse
  associationStatus: string
  encodingCreateCounter: number
  targetTag: string
  tagToWrite: string
  numWriteAttempt: number
  forceAssociation: boolean
  showPin: boolean
  pin: string
  tagsRead: TmrTag[]
  pendingEpcs: string[]
  sapMessageType: string
  sapMessage: string
  lastItemToAdd: TmrItem | undefined
  itemsCounter: number
  dailyEncodingCounter: number
}

export default class EncodingOutboundSapEncode extends Component<OperationReadingProps, State> {
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<OperationReadingState>(this.props)
  operation = RemoteOperation.getOperationConfig<EncodingOperationConfig>(this.matchParams.opCode)
  formSchema = EncodingExtensions.formSchema(this.operation)

  isEncoding = false
  autofocusWam = false
  timer: NodeJS.Timeout | null = null
  timerReader?: any
  timerTags: NodeJS.Timeout | null = null
  timerNoRead: NodeJS.Timeout | null = null

  state = {
    externalId: '',
    formData: this.locationState.formData ?? {},
    processing: false,
    loading: false,
    items: [],
    parcelCode: '',
    parcelCodeResetCounter: 0,
    parcelCodeVerified: false,
    wam: '',
    wamVerified: false,
    wamResetCounter: 0,
    deleteItems: false,
    antennaOn: false,
    associationStatus: 'TO_BE_READ',
    forceAssociation: false,
    configuration: [],
    encodingCreateCounter: 0,
    targetTag: '',
    tagToWrite: '',
    numWriteAttempt: 0,
    showPin: false,
    pin: '',
    tagsRead: [],
    pendingEpcs: [],
    sapMessageType: '',
    sapMessage: '',
    lastItemToAdd: undefined,
    itemsCounter: 0,
    dailyEncodingCounter: 0,
  } as any

  async componentDidMount() {
    await AppStore.checkEncodedItems(this.operation.code)
    RfidReader.setBatchInterval(this.operation.batchInterval)
    RfidReader.setBatchIntervalTagCount(this.operation.batchIntervalTagCount)
    RfidReader.setBatchIntervalTime(this.operation.batchIntervalTime)
    const counter = await AppStore.getEncodedItems(this.operation.code)
    this.setState({ dailyEncodingCounter: counter })
  }

  componentWillUnmount = async () => {
    this.clearTimerReader()
    this.clearTimer()
    await this.stopAntenna()
  }

  startAntenna = async () => {
    if (!RfidReader.isReading()) {
      await RfidReader.start()
    }
  }

  stopAntenna = async () => {
    if (RfidReader.isReading()) {
      await RfidReader.stop()
    }
  }

  changeParcelCode = async (txt) => {
    const { parcelCode, wamResetCounter } = this.state
    if (txt !== parcelCode) {
      await this.stopAntenna()
      if (txt === '') {
        this.setState(
          {
            parcelCode: txt,
            parcelCodeVerified: false,
            wam: '',
            wamVerified: false,
            wamResetCounter: this.state.wamResetCounter + 1,
            associationStatus: 'TO_BE_READ',
            pin: '',
            forceAssociation: false,
            encodingCreateCounter: 0,
            numWriteAttempt: 0,
            targetTag: '',
            tagToWrite: '',
            tagsRead: [],
            pendingEpcs: [],
            sapMessageType: '',
            sapMessage: '',
            processing: false,
          },
          this.resetValidateResponse
        )
      } else {
        this.setState({
          parcelCode: txt,
          parcelCodeVerified: false,
          wam: '',
          wamVerified: false,
          wamResetCounter: wamResetCounter + 1,
          items: [],
          itemsCounter: 0,
        })
      }
    }
  }

  doVerifyParcelCode = (txt) => {
    if (txt.length === 14) {
      this.setState({
        parcelCode: txt,
        parcelCodeVerified: true,
      })
    } else {
      showToastError('The parcel code must be 14 characters')
    }
  }

  changeWam = async (txt) => {
    if (txt !== this.state.wam) {
      await this.stopAntenna()
      if (txt === '') {
        this.clear()
      } else {
        this.setState({
          wam: txt,
          wamVerified: false,
        })
      }
    }
  }

  doVerifyWam = (txt) => {
    this.setState(
      {
        wam: txt,
        processing: true,
      },
      this.verifyWam
    )
  }

  verifyWam = async () => {
    const { parcelCode } = this.state
    try {
      const res = await EncodingExtensions.getItemConfiguration(
        this.operation,
        { wam: this.state.wam },
        { vblParcelCode: parcelCode }
      )
      this.setState(
        {
          encodingValidation: res.encodingValidation,
          encodingValidationResponse: res.encodingValidationResponse,
          processing: false,
        },
        this.startReader
      )
    } catch (error) {
      this.setState({ processing: false })
      showToastError(error)
    }
  }

  startReader = async () => {
    try {
      await RfidReader.initialize()
      RfidReader.setAutomaticStop(false)
      RfidReader.setOnTagReadCallback(this.onTagRead)
      RfidReader.onStartCallback = this.onStartCallback
      RfidReader.onStopCallback = this.onStopCallback
      await RfidReader.start()
    } catch (error) {
      showToastError((error as any).message ?? __(T.error.rfid_reader_initialization))
    }
  }

  onStartCallback = () => {
    this.setState({ antennaOn: true })
  }

  onStopCallback = () => {
    this.setState({ antennaOn: false })
  }

  onTagRead = async (tag: TmrTag) => {
    const { encodingValidation } = this.state
    if (!encodingValidation || !encodingValidation.identifiers) return
    if (this.isEncoding) return

    try {
      const tags = this.state.tagsRead.push(tag)
      this.clearTimerReader()
      this.clearTimer()
      await EncodingExtensions.onTagRead(encodingValidation!, tag, this.operation)
      this.setState({ encodingValidation, tagsRead: tags }, this.onTagReadTimer)
    } catch (error) {}
  }

  onTagReadTimer = () => {
    this.timer = setTimeout(() => {
      this.onTagReadValidate()
    }, this.operation.options.timeoutValidateTags ?? 700)
  }

  onTagReadValidate = async () => {
    try {
      this.isEncoding = true
      const res = await EncodingExtensions.validate(this.state.encodingValidation!)
      this.setState(
        {
          encodingValidationResponse: res.encodingValidationResponse,
          encodingValidation: res.encodingValidation,
        },
        this.onTagReadValidateResponse
      )
    } catch (error) {
      this.encodingError(error, 'Unknown error during encoding validation')
    } finally {
      this.isEncoding = false
    }
  }

  encodingError = (error?: any, text?: string) => {
    if (this.operation.options.enableSoundFeedbackKo) {
      Sounds.error()
    }
    showToastError(error && error.message ? error.message : text ?? 'Unknown error during encoding validation')
  }

  checkIfForceCreate = (value) => {
    const { encodingValidationResponse } = this.state
    let canForce = false
    if (getCanForceAssocation(this.operation) === value) {
      const notForcible = encodingValidationResponse?.errors.find((e) => !e.forcible)
      //se solo errori con forcible = true allora create forzata
      canForce = !notForcible
    }
    return canForce
  }

  onTagReadValidateResponse = () => {
    const { encodingValidationResponse, encodingCreateCounter, deleteItems } = this.state
    if (encodingValidationResponse) {
      if (deleteItems) {
        if (encodingValidationResponse.success) {
          this.removeOutboundWam()
        }
      } else {
        if (encodingValidationResponse.success) {
          this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.checkWriteTag)
        } else {
          if (this.checkIfForceCreate('yes')) {
            this.setState(
              { forceAssociation: true, pin: '', encodingCreateCounter: encodingCreateCounter + 1 },
              this.checkWriteTag
            )
          } else {
            this.checkAttachAutomaticReaderStop(encodingValidationResponse)
          }
        }
      }
    } else {
      this.checkAttachAutomaticReaderStop(encodingValidationResponse)
    }
  }

  checkAttachAutomaticReaderStop = (encodingValidationResponse) => {
    if (this.operation.autostopAntennaTimeout > 0) {
      this.isEncoding = false
      const blockedErrors = encodingValidationResponse
        ? encodingValidationResponse.errors.filter((e) => BLOCKED_ERRORS.includes(e.errorCode))
        : []
      if (blockedErrors.length === 0) {
        this.attachAutomaticReaderStop()
      } else {
        this.setState({ associationStatus: 'ERROR' })
      }
    }
  }

  checkWriteTag = async () => {
    const { encodingValidation } = this.state
    if (getEnableUhfWrite(this.operation) !== 'no') {
      await this.stopAntenna()

      //si prendono tag UHF
      const identifiers = encodingValidation?.identifiers || []
      const uhfIdentifiers: any[] = []
      let r = 0
      for (r = 0; r < identifiers.length; r++) {
        if (identifiers[r].identifierType === 'UHF_TAG') {
          uhfIdentifiers.push(identifiers[r])
        }
      }

      let identifierTag: any = undefined
      if (uhfIdentifiers.length === 1) {
        identifierTag = uhfIdentifiers[0]
      } else if (getUhfTagToWriteRegex(this.operation) !== '') {
        let error = false
        for (r = 0; r < uhfIdentifiers.length; r++) {
          if (uhfIdentifiers[r].code.match(getUhfTagToWriteRegex(this.operation))) {
            if (!identifierTag) {
              identifierTag = uhfIdentifiers[r]
            } else {
              error = true
            }
          }
        }
        if (error) {
          identifierTag = undefined
        }
      }

      if (identifierTag) {
        if (identifierTag.desiredCode) {
          this.create()
        } else {
          //se solo un tag uhf si chiede nuovo tag -> computeTags
          this.setState({ targetTag: identifierTag.code }, this.computeTags)
        }
      } else {
        this.clear()
        showToastError('UHF tag to write not found')
      }
    } else {
      this.create()
    }
  }

  attachAutomaticReaderStop = () => {
    if (this.operation.autostopAntennaTimeout > 0) {
      this.clearTimerReader()
      this.timerReader = setTimeout(async () => {
        if (!this.isEncoding) {
          await this.stopAntenna()
          this.isEncoding = true
          if (this.checkIfForceCreate('yes')) {
            this.isEncoding = true
            this.setState(
              { forceAssociation: true, pin: '', encodingCreateCounter: this.state.encodingCreateCounter + 1 },
              this.create
            )
          } else {
            this.setState({ encodingCreateCounter: this.state.encodingCreateCounter + 1 }, this.create)
          }
        }
      }, this.operation.autostopAntennaTimeout)
    }
  }

  computeTags = async () => {
    const { targetTag, wam, numWriteAttempt, encodingValidationResponse } = this.state
    const initialType = getInitialType(this.operation)
    try {
      const res = await Encodings.computeTags(
        this.operation.id,
        [{ code: targetTag, identifierType: 'UHF_TAG' }],
        AppStore.defaultWorkstation!.id,
        '', //entity id
        encodingComputeTagsEntityCode(encodingValidationResponse, initialType, { wam: wam }) //entity code
      )
      if (res && res.length == 1 && res[0].desiredCode) {
        //si scrive il nuovo tag con la write del reader
        this.setState({ tagToWrite: res[0].desiredCode, numWriteAttempt: numWriteAttempt + 1 }, this.writeTag)
      } else {
        // errore
        showToastError('Generation of the tag to write failed')
        //this.clear()
      }
    } catch (error) {
      showToastError(error)
      //this.clear()
    }
  }

  writeTag = async () => {
    const { targetTag, tagToWrite, encodingValidation } = this.state
    try {
      const ret: any = await RfidReader.writeEpc(targetTag, tagToWrite)
      if (ret.ok) {
        if (encodingValidation) {
          //si aggiorna encodingValidation aggiungendo attributo all'identifier
          const identifiers = encodingValidation?.identifiers || []
          const index = identifiers.findIndex((el) => el.code === targetTag)
          if (index >= 0) {
            encodingValidation.identifiers[index].desiredCode = tagToWrite
          }
          this.setState({ encodingValidation: encodingValidation }, this.create)
        }
      } else {
        this.writeTagError()
      }
    } catch (error) {
      this.writeTagError()
    }
  }

  writeTagError = async () => {
    const { numWriteAttempt } = this.state
    if (numWriteAttempt === MAX_WRITE_ATTEMPT) {
      const ok = await askUserConfirmation(__(T.misc.write_tag), __(T.misc.retry_write_tag))
      if (ok) {
        this.setState({ numWriteAttempt: 1 }, this.writeTag)
      } else {
        showToastError(__(T.error.write_tag))
        this.clear()
      }
    } else {
      this.setState({ numWriteAttempt: numWriteAttempt + 1 }, this.writeTag)
    }
  }

  create = async () => {
    const { forceAssociation, pin, encodingValidation, encodingValidationResponse, tagsRead } = this.state
    if (encodingValidation) {
      await this.stopAntenna()
      try {
        let res
        if (forceAssociation) res = await EncodingExtensions.force_create(encodingValidation!, pin)
        else res = await EncodingExtensions.create(encodingValidation!)
        if (!res) throw new Error(__(T.error.item_creation_error))
        this.setState({
          encodingCreateResponse: res,
          processing: false,
          associationStatus: getEncodingAssociationStatus(res, encodingValidationResponse),
        })
        this.isEncoding = false
        if (res.success) {
          await AppStore.increaseDailyEncodedItems(this.operation.code)
          await AppStore.increaseEncodedItems(this.operation.code)
          //check messageType and message item attributes
          const message =
            res.item && res.item.attributes && res.item.attributes.message
              ? res.item.attributes.message
              : __(T.error.item_creation_error)
          const messageType =
            res.item && res.item.attributes && res.item.attributes.messageType ? res.item.attributes.messageType : 'E'
          if (messageType != 'E') {
            //check operationToPerform to call insertOutboundWam
            const operationToPerform = res.operationToPerform || 'FAIL'
            if (operationToPerform === 'NONE') {
              this.setState({ lastItemToAdd: res.item }, this.insertOutboundWam)
            } else {
              this.setState(
                { sapMessageType: messageType, sapMessage: message, lastItemToAdd: res.item },
                this.successEncoding
              )
            }
          } else {
            showToastError(message, __(T.error.error), true, this.closeModalError, 'OK')
          }
        } else {
          await this.startAntenna()
          this.setState({ processing: false })
          this.encodingError(undefined, __(T.error.item_creation_error))
        }
      } catch (error) {
        await this.startAntenna()
        this.removeTagReadFromReader(tagsRead)
        this.isEncoding = false
        this.encodingError(error, 'Unknown error during encoding validation')
      }
    } else {
      this.setState({ processing: false })
      this.encodingError(undefined, __(T.error.item_creation_error))
    }
  }

  closeModalError = () => {
    this.clear()
  }

  addPendingTags = () => {
    const { pendingEpcs, tagsRead } = this.state
    for (let t = 0; t < tagsRead.length; t++) pendingEpcs.push(tagsRead[t].epc)
    return pendingEpcs
  }

  insertOutboundWam = async () => {
    const { parcelCode, wam } = this.state
    try {
      const res: any = await Encodings.executeAction(
        this.operation.id,
        'insertOutboundWam',
        parcelCode,
        wam,
        AppStore.defaultWorkstation ? AppStore.defaultWorkstation.id : ''
      )
      const messageType = res.messageType || 'I'
      if (messageType) {
        this.setState({ sapMessageType: messageType, sapMessage: res.message || 'Generic error' }, this.successEncoding)
      } else {
      }
    } catch (error) {
      this.setState({ processing: false })
      showToastError(error)
    }
  }

  successEncoding = async () => {
    const { sapMessageType, sapMessage, lastItemToAdd, itemsCounter, wam } = this.state
    const tmpItems = this.state.items
    if (sapMessageType === 'W') {
      showToast({
        title: __(T.misc.warning),
        description: sapMessage,
        status: 'warning',
      })
    } else {
      showToast({ status: 'success', description: __(T.messages.encoding_success) })
    }
    if (this.operation.options.enableSoundFeedbackOk) {
      Sounds.success()
    }

    if (lastItemToAdd) {
      const counter = await AppStore.getEncodedItems(this.operation.code)
      if (!lastItemToAdd.id || lastItemToAdd.id === null) {
        lastItemToAdd.id = Date.now()
        lastItemToAdd.fakeItemID = true
      }
      if (lastItemToAdd.attributes && !lastItemToAdd.attributes.wam) {
        lastItemToAdd.attributes.wam = wam
      }
      tmpItems.push({ item: lastItemToAdd })
      this.setState({
        itemsCounter: itemsCounter + 1,
        items: tmpItems,
        dailyEncodingCounter: counter,
        lastItemToAdd: undefined,
      })
    }

    setTimeout(async () => {
      this.clear(true)
    }, this.operation.options.resetReadingsAfterOperation || 300)
  }

  removeOutboundWam = async () => {
    const { parcelCode, wam, encodingValidationResponse, itemsCounter } = this.state
    const tmpItems = this.state.items
    try {
      const res: any = await Encodings.executeAction(
        this.operation.id,
        'removeOutboundWam',
        parcelCode,
        wam,
        AppStore.defaultWorkstation ? AppStore.defaultWorkstation.id : ''
      )
      if (res) {
        const Messaggio = res.Messaggio ?? __(T.error.remove_sap_item_error)
        const Tipo = res.Tipo ?? 'E'
        if (Tipo != 'E') {
          const find = tmpItems.findIndex((itm) => itm.item.attributes.wam === wam)
          if (find >= 0) {
            tmpItems.splice(find, 1)
          }
          this.setState({ itemsCounter: itemsCounter - 1, items: tmpItems })
          this.clear(true)
        } else {
          showToastError(Messaggio, __(T.error.error), true, this.closeModalRemoveError, 'OK')
        }
      } else {
        showToastError(__(T.error.remove_sap_item_error), __(T.error.error), true, this.closeModalRemoveError, 'OK')
      }
    } catch (error) {
      this.setState({ processing: false })
      showToastError(error)
    }
  }

  closeModalRemoveError = () => {
    this.clear(true)
  }

  removeTagReadFromReader = (tags: TmrTag[]) => {
    const toRemove: string[] = []
    for (let t = 0; t < tags.length; t++) {
      toRemove.push(tags[t].epc)
    }
    RfidReader.removeTags(toRemove)
  }

  isCanForceAssociation = () => {
    const { encodingValidationResponse } = this.state
    if (encodingValidationResponse) {
      //se tutti errori forzabili
      const notForcible = encodingValidationResponse.errors.find((e) => !e.forcible)
      return !notForcible
    }
    return false
  }

  checkForceAssociation = () => {
    const { encodingCreateCounter } = this.state
    const canForceAssociation = getCanForceAssocation(this.operation)
    if (canForceAssociation === 'no') {
      this.clear()
    } else {
      if (this.isCanForceAssociation()) {
        if (canForceAssociation === 'withPin') {
          this.managerPinModal()
        } else if (canForceAssociation === 'afterRetry') {
          if (encodingCreateCounter === 1) {
            this.setState({ encodingCreateCounter: encodingCreateCounter + 1 }, this.create)
          } else if (encodingCreateCounter === 2) {
            this.setState(
              { forceAssociation: true, pin: '', encodingCreateCounter: encodingCreateCounter + 1 },
              this.create
            )
          } else {
            this.clear()
          }
        } else if (canForceAssociation === 'yes') {
          this.setState(
            { forceAssociation: true, pin: '', encodingCreateCounter: encodingCreateCounter + 1 },
            this.create
          )
        } else {
          this.clear()
        }
      } else {
        this.clear()
      }
    }
  }

  managerPinModal = () => {
    this.setState({ showPin: !this.state.showPin })
  }

  forceWithPin = (pin) => {
    this.setState({ pin: pin, forceAssociation: true, showPin: false }, this.create)
  }

  clearTimerReader = () => {
    if (this.timerReader) {
      clearTimeout(this.timerReader)
      this.timerReader = null
    }
  }

  clearTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  }

  _onChangeDeleteItems = () => {
    const { pendingEpcs } = this.state
    if (pendingEpcs.length > 0) {
      RfidReader.removeTags(pendingEpcs)
    }
    this.setState({ deleteItems: !this.state.deleteItems, pendingEpcs: [] })
  }

  goBack = () => {
    if (this.formSchema.length) {
      Router.navigate('/encoding/encodingOutboundSap/:opCode', {
        opCode: this.operation.code,
      })
    } else {
      Router.navigate('/')
    }
  }

  getHeaderRight = () => {
    const { deleteItems, antennaOn, dailyEncodingCounter } = this.state
    return (
      <>
        <BoxCounter row vcenter>
          <Icons.Confirmed width={34} height={34} />
          <Counter>{dailyEncodingCounter}</Counter>
        </BoxCounter>
        <EncodingDailyItems
          code={this.operation.code}
          style={{ minWidth: 100, marginRight: 15, borderRadius: 10, border: '1px solid #dcdcdc', height: 60 }}
        />
        <BoxDelete>
          <ToggleField onChange={() => this._onChangeDeleteItems()} checked={deleteItems} deleteIcon />
        </BoxDelete>
        <Box vcenter>
          {!antennaOn ? (
            <Icons.Antenna style={{ height: 35, width: 60, marginLeft: 20 }} />
          ) : (
            <Icons.AntennaOn style={{ height: 35, marginLeft: 20 }} />
          )}
        </Box>
      </>
    )
  }

  clear = (pending = false) => {
    this.autofocusWam = pending
    let pendingTags = []
    if (pending) {
      pendingTags = this.addPendingTags()
    } else {
      RfidReader.clear()
    }
    this.setState(
      {
        wam: '',
        wamVerified: false,
        wamResetCounter: this.state.wamResetCounter + 1,
        associationStatus: 'TO_BE_READ',
        pin: '',
        forceAssociation: false,
        encodingCreateCounter: 0,
        numWriteAttempt: 0,
        targetTag: '',
        tagToWrite: '',
        tagsRead: [],
        pendingEpcs: pendingTags,
        sapMessageType: '',
        sapMessage: '',
        processing: false,
      },
      this.resetValidateResponse
    )
  }

  resetValidateResponse = () => {
    this.setState({
      encodingValidation: undefined,
      encodingValidationResponse: undefined,
      encodingCreateResponse: undefined,
    })
    if (this.autofocusWam) {
      document.getElementById('wam')?.focus()
    }
  }

  clearReads = () => {}

  getCounterLayout = () => {
    const { encodingValidation } = this.state
    return {
      disableSpacer: !encodingValidation,
      spacerHeight: 10,
      sizeText: '16px',
      lineHeightText: '20px',
      size: '64px',
      interline: '68px',
      minHeight: '120px',
    }
  }

  render() {
    const {
      items,
      formData,
      loading,
      processing,
      parcelCodeVerified,
      encodingValidation,
      wamVerified,
      associationStatus,
      showPin,
      wamResetCounter,
      itemsCounter,
    } = this.state
    const identifiers = encodingValidation?.identifiers
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        forceDetails="top"
        header={{
          details: {
            data: formData,
            formSchema: this.formSchema,
          },
        }}
        headerRight={this.getHeaderRight()}
        enableEmulation
      >
        <Box flex row>
          <Page.Sidebar width={400} style={{ paddingRight: 15 }}>
            <Box mb={10}>
              <Input
                label={__(T.misc.parcel)}
                onChange={(txt) => this.changeParcelCode(txt)}
                onEnter={(txt) => this.doVerifyParcelCode(txt)}
                customIcon={<Icons.Barcode width={40} height={40} />}
                autoFocus
                showClear={parcelCodeVerified}
              />
            </Box>
            <Box mb={10}>
              <Input
                startFocus
                autoFocus
                label={__(T.custom.WAM)}
                onChange={(txt) => this.changeWam(txt)}
                onEnter={(txt) => this.doVerifyWam(txt)}
                customIcon={<Icons.Barcode width={40} height={40} />}
                disabled={wamVerified || !parcelCodeVerified}
                resetCounter={wamResetCounter}
                id="wam"
              />
            </Box>
            <TagCounter detected={itemsCounter} layout={this.getCounterLayout()} />
            {/* integrare identifiers */}
            {identifiers && (
              <EncodingIdentifers
                identifiers={identifiers}
                clearReads={this.clearReads}
                encodingValidation={encodingValidation}
                associationStatus={associationStatus}
                marginBottom={12}
                layout={{
                  iconSize: 34,
                  iconMarginBottom: 0,
                  iconMarginRight: 10,
                  fontSizeTagType: 15,
                  fontSizeCode: 11,
                  fontSizeStatus: 12,
                  paddingHorizontal: 10,
                  minHeight: '100px',
                  widthPerc: identifiers.length === 1 ? 100 : 49,
                  row: true,
                }}
              />
            )}
          </Page.Sidebar>
          <Box flex bg={'#FFF'}>
            <Page.Content notBoxed style={{ margin: 0 }}>
              <Box ml={15} mr={15} mt={15} flex>
                <OperationReadingList extension={EncodingExtensions} items={items} operation={this.operation} />
              </Box>
            </Page.Content>
          </Box>
        </Box>
        {processing && <FullLoadingLayer message={__(T.messages.operation_in_progress)} />}

        {showPin && (
          <InputModal
            title={__(T.titles.pin_modal)}
            labelInput="Pin"
            onClose={() => this.managerPinModal()}
            onConfirm={this.forceWithPin}
          />
        )}
      </Page>
    )
  }
}

const BoxDelete = styled(Box)`
  border: 1px solid #dcdcdc;
  background-color: #fbfbfb;
  padding: 7px 10px 7px 7px;
  border-radius: 10px;
`

const BoxCounter = styled(Box)`
  border: 1px solid #dcdcdc;
  height: 60px;
  padding: 0px 20px 0px 15px;
  border-radius: 10px;
  margin-right: 15px;
`

const Counter = styled.div`
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #454545;
  margin-left: 10px;
`
