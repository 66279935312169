import { Component } from 'react'
import { Box, FormSchemaForm, OnSelectAsnCodeParams, Page } from 'stylewhere/components'
import { Router, ShippingOperationConfig, OperationConfig } from 'stylewhere/shared'
import { ShippingExtensions } from 'stylewhere/extensions'
import { isModalError } from 'stylewhere/utils'
import { T, __ } from 'stylewhere/i18n'

export interface Props {
  operation: ShippingOperationConfig
  onSelectAsnCode: (params: OnSelectAsnCodeParams) => void
  onBackPress?: () => void
}

const formSchema = [
  {
    icon: 'Barcode',
    name: 'shipmentCode',
    type: 'text',
    label: {
      en: 'Shipment Code',
      it: 'Codice Spedizione',
    },
    required: true,
    autoFocus: true,
    submitOnEnter: true,
  },
]

export class ShippingByAsnStartInput extends Component<Props> {
  isModal = false

  schema = ShippingExtensions.formSchema({ formSchema } as ShippingOperationConfig)

  componentDidMount() {
    this.isModal = isModalError(this.props.operation)
  }

  render() {
    const { operation, onSelectAsnCode, onBackPress } = this.props
    return (
      <Page
        title={operation.description}
        onBackPress={onBackPress ?? (() => Router.navigate('/'))}
        enableEmulation={false}
      >
        <Page.Content notBoxed>
          <Box flex>
            <Box flex center>
              <FormSchemaForm
                schema={this.schema}
                submitText={__(T.misc.next)}
                onSubmit={(form: any) => {
                  console.log(form)
                  onSelectAsnCode(form.shipmentCode)
                }}
                formContext="start"
                // gridFieldColumn={operation.formSchemaSingleRow || formRowField ? 1 : 2}
                operation={operation}
                // formDataUpdated={formDataUpdated}
              />
            </Box>
          </Box>
        </Page.Content>
      </Page>
    )
  }
}
