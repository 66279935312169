import { create } from 'apisauce'
import {
  api,
  DecodedItemSorting,
  responseErrorCheck,
  Sorting,
  TmrBaseResource,
  TmrSimpleAttributesMap,
  TmrZone,
} from 'stylewhere/api'
import { AppStore } from 'stylewhere/shared'

export interface SortingsDecodePayload {
  sortingId: string
  operationId?: string
  workstationId?: string
  itemIdentifierCode: string
  originZoneId: string
  attributes?: TmrSimpleAttributesMap
}

export interface SortingsDeletePayload {
  sortingId: string
  operationId: string
}

export interface SortingsClosePayload {
  sortingId: string
  operationId: string
  attributes?: TmrSimpleAttributesMap
}

export class Sortings extends TmrBaseResource {
  static endpoint = 'api/v1/sortings'
  static endpointOperation = 'api/v1/operations/sorting'

  static async getById(id: string, requestConfig?) {
    const result = await api.get<Sorting>(`${this.endpoint}/${id}`, requestConfig)
    return responseErrorCheck(result)
  }

  static async decode(decodePayload: SortingsDecodePayload) {
    if (decodePayload?.attributes) {
      decodePayload.attributes.workstationCode = AppStore.defaultWorkstation!.code
    } else {
      decodePayload.attributes = { workstationCode: AppStore.defaultWorkstation!.code }
    }
    const res = await api.post<DecodedItemSorting>(`${this.endpointOperation}/read`, decodePayload)
    return responseErrorCheck(res)
  }

  static async print(zpl: string, endpoint: string) {
    const printerEndpoint = create({
      baseURL: 'http://' + endpoint,
      timeout: 3000,
    })
    printerEndpoint.setHeaders({
      Accept: 'application/x-www-form-urlencoded',
      'Content-Type': 'application/x-www-form-urlencoded',
    })
    return printerEndpoint.post(`pstprnt`, zpl).then(responseErrorCheck)
  }

  static async partialConfirm(sortingId: string, operationId: string, transactionDate: string, async: boolean) {
    const endpoint = async ? '/asyncExecution' : ''
    const res = await api.post(`${this.endpointOperation}/partialConfirm` + endpoint, {
      sortingId,
      operationId,
      attributes: { transactionDate },
    })
    return responseErrorCheck(res)
  }

  static async delete(deletePayload: SortingsDeletePayload) {
    const res = await api.post<DecodedItemSorting>(`${this.endpointOperation}/delete`, deletePayload)
    return responseErrorCheck(res)
  }

  static async close(closePayload: SortingsClosePayload, async: boolean) {
    if (closePayload?.attributes) {
      closePayload.attributes.workstationCode = AppStore.defaultWorkstation!.code
    } else {
      closePayload.attributes = { workstationCode: AppStore.defaultWorkstation!.code }
    }
    const endpoint = async ? '/asyncExecution' : ''
    const res = await api.post<DecodedItemSorting>(`${this.endpointOperation}/close` + endpoint, closePayload)
    return responseErrorCheck(res)
  }

  static async createFromPicking<T>(pickingIds: string[], operationId: string, formData?: any) {
    const res = await api.post<T>(`${this.endpointOperation}/createFromPicking`, {
      ...formData,
      pickingIds,
      operationId,
      operationPlaceId: AppStore.defaultWorkstation!.placeId,
    })
    return responseErrorCheck(res)
  }

  static async getOriginZones<TmrZone>(id: string, requestConfig?) {
    const result = await api.get<TmrZone[]>(`${this.endpoint}/${id}/originZones`, requestConfig)
    return responseErrorCheck(result)
  }

  static async countersByZone<T>(operationId: string, sortingId: string, zoneIds: string[]) {
    const res = await api.post<T>(`${this.endpointOperation}/countersByZone`, {
      operationId,
      sortingId,
      zoneIds,
    })
    return responseErrorCheck(res)
  }
}
