import { useState } from 'react'
import styled from '@emotion/styled'
import { Box, Icons } from 'stylewhere/components'

export function Section({ header, body }: { header: any; body: any }) {
  const [open, setopen] = useState(false)
  const iconStyle = {
    marginRight: 10,
    width: 20,
    color: 'black',
  }
  return (
    <SectionContainer>
      <SectionHeader onClick={() => setopen(!open)} row vcenter>
        {!open && <Icons.ArrowDownBold style={iconStyle} />}
        {open && <Icons.ArrowUpBold style={iconStyle} />}
        {header}
      </SectionHeader>
      {open && body}
    </SectionContainer>
  )
}

const SectionContainer = styled(Box)`
  margin-bottom: 20px;
`

const SectionHeader = styled(Box)`
  background: #f1f1f1;
  border: 1px solid #eaeaea;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 85px;
  font-size: 26px;
  font-weight: 700;
  padding-left: 30px;
`
