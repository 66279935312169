import { Component } from 'react'
import { Router, ShippingOperationConfig, RemoteOperation, getDataFromSchema } from 'stylewhere/shared'
import { isModalError, showToastError } from 'stylewhere/utils'
import { Shippings, ShippingParcel, Asn, Asns, Parcels, Zones, TmrZone } from 'stylewhere/api'
import type { Routes } from 'stylewhere/pages'
import { RouteComponentProps, StaticContext } from 'react-router'
import { T, __ } from 'stylewhere/i18n'
import { Box, Icons, OperationStart, Page } from 'stylewhere/components'
import { ShippingExtensions } from 'stylewhere/extensions'

export interface ShippingParcelAddParams {
  opCode: string
  asnId: string
  parcelId: string
}

interface State {
  asn?: Asn | undefined
  parcel?: ShippingParcel | undefined
  loading: boolean
  zone?: TmrZone | undefined
}

interface LocationState {
  asn?: Asn
  parcel?: ShippingParcel
  zone?: TmrZone
}

export default class ShippingParcelStart extends Component<
  RouteComponentProps<ShippingParcelAddParams, StaticContext, LocationState>,
  State
> {
  asnId: string
  parcelId: string
  opCode: string
  isModal: boolean
  operation: ShippingOperationConfig
  submitPath: Routes = '/shipping/:opCode/reading' // FIXME
  backPath: Routes = '/shipping/:opCode/asn/:asnId'
  state: State = {
    loading: true,
  }
  locationState = Router.getLocationState<LocationState>(this.props)

  constructor(props: any) {
    super(props)
    const matchParams: ShippingParcelAddParams = Router.getMatchParams(this.props)
    this.asnId = matchParams.asnId
    this.parcelId = matchParams.parcelId
    this.opCode = matchParams.opCode
    this.isModal = false
    this.operation = RemoteOperation.getOperationConfig<ShippingOperationConfig>(this.opCode)
  }

  async componentDidMount() {
    this.isModal = isModalError(this.operation)
    try {
      let asn: State['asn'], parcel: State['parcel'], zone: State['zone']

      if (this.locationState?.asn) {
        asn = this.locationState?.asn
      } else {
        const loadedAsn = await Asns.get<Asn>(this.asnId)
        if (loadedAsn) {
          asn = loadedAsn
        }
      }

      // parcel id is not always available, when we add a new parcel it should be empty
      if (this.parcelId) {
        if (this.locationState?.parcel) {
          parcel = this.locationState?.parcel
        } else {
          const loadedParcel = await Parcels.get<ShippingParcel>(this.parcelId)
          if (loadedParcel) parcel = loadedParcel
        }

        if (this.locationState?.zone) {
          zone = this.locationState?.zone
        } else {
          if (parcel && parcel.attributes?.stockZoneId) {
            const loadedZone = await Zones.get<TmrZone>(parcel.attributes?.stockZoneId)
            if (loadedZone) zone = loadedZone
          }
        }

        this.setState({ asn, parcel, zone, loading: false })
      } else {
        this.setState({ asn, loading: false })
      }
    } catch (error) {
      showToastError(error, __(T.error.error), this.isModal)
      this.setState({ loading: false })
    }
  }

  async loadAsn(id: string): Promise<Asn | undefined> {
    return Asns.get<Asn>(id)
  }

  onSubmit = async (formData, operation: ShippingOperationConfig, schema) => {
    const { asn, parcel: selectedParcel } = this.state
    if (!asn) {
      showToastError(__(T.misc.shipment), __(T.error.error), this.isModal)
      return
    }

    try {
      let parcel = selectedParcel
      if (operation.hasChecklist) {
        const payloadStartParcel: any = getDataFromSchema(formData, schema) || {}
        parcel = await Shippings.startParcel(operation, {
          ...payloadStartParcel,
          asn: { code: asn.code },
        })
      }

      Router.navigate(
        this.submitPath,
        { opCode: operation.code, asnId: this.asnId },
        {
          state: {
            formData,
            parcel,
            asn,
            backRoute: this.backPath,
            backRouteParams: {
              opCode: this.opCode,
              asnId: this.asnId,
            },
          },
        }
      )
    } catch (err) {
      showToastError(err, 'Start Parcel Error', this.isModal)
    }
  }

  render() {
    const { parcel, zone, loading } = this.state
    const initialValues: Record<string, unknown> = {}

    if (parcel) {
      initialValues.parcelCode = parcel.code
      if (zone) {
        initialValues.attributes = {
          stockZoneId: zone,
        }
      }
    }

    if (loading) {
      return (
        <Page
          title={__(T.misc.loading)}
          onBackPress={() => {
            Router.navigate(this.backPath, { opCode: this.opCode, asnId: this.asnId })
          }}
        >
          <Page.Content notBoxed>
            <Box flex center>
              <Icons.Loader1 />
            </Box>
          </Page.Content>
        </Page>
      )
    }

    return (
      <OperationStart
        backPath={Router.replaceMatchParams(this.backPath, { asnId: this.asnId })}
        startWithRfidSearch={false}
        submitPath={this.submitPath}
        extensions={ShippingExtensions}
        onSubmit={this.onSubmit}
        initialValues={initialValues}
      />
    )
  }
}
