import React, { Component, useEffect, useState } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { format } from 'date-fns'
import {
  AntennaButton,
  Page,
  Spacer,
  Box,
  Button,
  Icons,
  EmptyListMessage,
  Modal,
  Table,
  Input,
  SortingGroupLabel,
  SortingProductInfo,
  SortingHeader,
  Text,
  ToggleField,
  DatePicker,
  Stoplight,
} from 'stylewhere/components'
import {
  DecodedItemSorting,
  Sorting,
  SortingGroup,
  SortingGroupEntries,
  SortingGroupEntry,
  SortingGroupRead,
  SortingGroupReads,
  SortingGroups,
  Sortings,
  SortingsDecodePayload,
  Items,
  DecodedItem,
  TmrZone,
} from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import {
  Router,
  RemoteOperation,
  SortingOperationConfig,
  OperationReadingProps,
  OperationReadingState,
  AppStore,
  RfidReader,
  getDataFromSchema,
  OperationReadingProvider,
  usePrevState,
} from 'stylewhere/shared'
import { SortingExtensions } from 'stylewhere/extensions'
import {
  showToast,
  showToastError,
  askUserConfirmation,
  getEpc,
  getSerial,
  openModal,
  closeModal,
  isModalError,
  hasAsyncConfirm,
  getSseEndpoint,
  RETRY_SSE_TIME,
} from 'stylewhere/utils'
import { config } from 'stylewhere/config'
import { last } from 'lodash'

interface State extends OperationReadingState {
  items: DecodedItemSorting[]
  sorting?: Sorting
  currentItem?: DecodedItemSorting
  currentSortingGroupRead?: SortingGroupRead
  currentTag?: string
  readsCount: number
  expectedCount: number
  inDecoding: boolean
  originZones: TmrZone[]
  originZoneId: string
  inError: boolean
  reloadingCounter: boolean
  pricing: boolean
  confirming: boolean
  sessionReadingCount: number
  transactionDate: string
  sseStatus: boolean
  retrySseCount: number
  lastAsyncID: string
  asyncConfirmResult?: any
  asyncPartialConfirm?: boolean
  lastZPL?: { code: string; zpl: string }
  ignoredCount: number
}

export default class SortingReading extends Component<OperationReadingProps<State>, State> {
  antennaRef
  matchParams = Router.getMatchParams(this.props)
  locationState = Router.getLocationState<State>(this.props)
  operation = RemoteOperation.getOperationConfig<SortingOperationConfig>(this.matchParams.opCode)
  formSchema = SortingExtensions.formSchema(this.operation)
  isModal = false

  state: State = {
    items: [],
    loading: true,
    formData: this.locationState.formData,
    readsCount: 0,
    expectedCount: 0,
    inDecoding: false,
    originZones: [],
    originZoneId: '',
    inError: false,
    reloadingCounter: false,
    pricing: true,
    confirming: false,
    sessionReadingCount: 0,
    transactionDate: '',
    sseStatus: false,
    retrySseCount: 0,
    lastAsyncID: '',
    ignoredCount: 0,
  }
  sse: any
  constructor(props) {
    super(props)
    this.antennaRef = React.createRef()
  }

  componentDidMount() {
    this.isModal = isModalError(this.operation)
    RfidReader.setAutomaticStop(this.operation.autostopAntennaTimeout > 0)
    RfidReader.setAutomaticStopTime(this.operation.autostopAntennaTimeout)
    RfidReader.setDecodeFunction(Items.batchDecode)
    RfidReader.setOnDecodedItemCallback(this.onItemDecoded)
    this.getSorting()
    this.setState({ sseStatus: !hasAsyncConfirm(this.operation) }, this.attachSse)
  }

  attachSse = async () => {
    if (!hasAsyncConfirm(this.operation) || !AppStore.loggedUser) {
      return
    }
    const sseUrl = getSseEndpoint(config)
    this.sse = new EventSource(sseUrl, { withCredentials: false })
    this.sse.onerror = () => {
      this.setState({ sseStatus: false, retrySseCount: this.state.retrySseCount + 1 }, this.retrySse)
    }
    this.sse.onopen = (event) => {
      this.setState({ sseStatus: true, retrySseCount: 0 })
    }
    this.sse.onmessage = (event) => {
      this.sseEventMessage(event)
    }
  }

  sseEventMessage = async (event) => {
    const { lastAsyncID, formData, asyncConfirmResult, asyncPartialConfirm } = this.state
    const eventData = event.data ? JSON.parse(event.data) : undefined
    const confirmData = getDataFromSchema(formData, this.formSchema)
    if (eventData && eventData.data) {
      if (eventData.data.asyncExecutionId === lastAsyncID) {
        if (eventData.data.executionStatus === 'OK') {
          if (!asyncPartialConfirm) {
            await SortingExtensions.afterConfirm(this.operation, confirmData, asyncConfirmResult)
            this.setState({ confirming: false })
            showToast({
              title: __(T.misc.success),
              description: __(T.messages.generic_success, { code: this.operation.description }),
              status: 'success',
            })
            this.goBack()
          } else {
            this._partialConfirmSuccess(asyncConfirmResult)
          }
        } else {
          this.setState({ confirming: false })
          showToastError(new Error(eventData.data.details), __(T.error.error), this.isModal)
        }
      }
    }
  }

  retrySse = () => {
    this.destroySse()
    if (this.state.retrySseCount === 1) {
      this.attachSse()
    } else {
      setTimeout(() => {
        this.attachSse()
      }, RETRY_SSE_TIME)
    }
  }

  destroySse = () => {
    if (this.sse) {
      this.sse.close()
      this.sse = undefined
    }
  }

  getSorting = async () => {
    const { formData } = this.state
    if (formData && formData.sortingId) {
      const res = await Sortings.getById(formData.sortingId)
      if (res) {
        let error = false
        let zones: TmrZone[] = []
        let reads = res.readsCount ?? 0
        let expected = res.expectedCount ?? 0
        if (this.operation.enableOriginZone) {
          zones = await Sortings.getOriginZones(formData.sortingId)
          if (!zones || zones.length === 0) {
            error = true
          } else {
            const counter: any = await this.getCounterByZones(zones[0].id)
            reads = counter && counter.read ? counter.read : 0
            expected = counter && counter.expected ? counter.expected : 0
          }
        }

        this.setState({
          sorting: res,
          originZones: zones,
          originZoneId: zones.length > 0 ? zones[0].id : '',
          readsCount: reads,
          expectedCount: expected,
          inError: error,
        })
      } else {
        this.goBack()
      }
    } else {
      this.goBack()
    }
  }

  getCounterByZones = async (zoneId) => {
    const { formData } = this.state
    const counter: any = await Sortings.countersByZone(this.operation.id, formData.sortingId, [zoneId])
    return counter
  }

  changeZone = (value) => {
    if (value.id !== this.state.originZoneId) {
      this.setState(
        {
          originZoneId: value.id,
          reloadingCounter: true,
          currentItem: undefined,
        },
        this.reloadCounter
      )
    }
  }

  reloadCounter = async () => {
    const { originZoneId } = this.state
    const counter: any = await this.getCounterByZones(originZoneId)
    this.setState({
      items: [],
      reloadingCounter: false,
      readsCount: counter && counter.read ? counter.read : 0,
      expectedCount: counter && counter.expected ? counter.expected : 0,
    })
  }

  onItemDecoded = async (decodedItems: { [epc: string]: DecodedItem<string> }) => {
    const { formData, items, originZoneId, pricing } = this.state
    this.setState({ inDecoding: true })
    const identifierCodes = Object.keys(decodedItems)
    RfidReader.removeTags(RfidReader.tags.map(tag => tag.epc).splice(0, RfidReader.tags.length -1))
    const decodePayload = getDataFromSchema<SortingsDecodePayload>(formData, this.formSchema)
    //decodePayload.operationId = this.operation.id
    decodePayload.workstationId = AppStore.defaultWorkstation?.id
    // La Sorting non usa originZoneId nella confirm (confirmValuePath = false nel formSchema),
    // ma lo richiede nella read, quindi va aggiunto qui a mano
    if (this.operation.enableOriginZone) {
      decodePayload.originZoneId = originZoneId
    }
    if (this.operation.pricingEnabled) {
      if (!decodePayload.attributes) decodePayload.attributes = {}
      decodePayload.attributes.pricing = pricing ? 'true' : 'false'
    }
    // Prendi solo il primo item letto, gli altri sono ignorati
    decodePayload.itemIdentifierCode = identifierCodes[0]
    decodePayload.sortingId = formData.sortingId
    try {
      const currentItem = await Sortings.decode(decodePayload)

      if (currentItem) {
        const currentSortingGroupRead = currentItem.sortingGroupRead
        if (currentItem.statuses.includes('INVALID_IDENTIFIER') || currentItem.statuses.includes('ITEM_UNKNOWN')) {
          this.setState((prevState) => ({
            ignoredCount: prevState.ignoredCount + 1,
            inDecoding: false,
          }))
          return
        }

        if (currentItem.item) {
          if (currentItem.statuses.includes('ALREADY_READ')) {
            const tagInCache = RfidReader.tags.map(tag => tag.epc).includes(currentItem.epc as string)
            currentItem.item.status = 'ignored'
            this.setState({
              currentItem,
              currentSortingGroupRead,
              inDecoding: false,
            })
            if(tagInCache)return
          }

          const _items = await OperationReadingProvider.processDecodedItems<DecodedItemSorting[]>(
            this.operation,
            { [identifierCodes[0]]: currentItem },
            items,
            formData,
            SortingExtensions
          )

          const counter: any = await this.getCounterByZones(originZoneId)
          this.setState({
            items: _items,
            currentItem,
            sessionReadingCount: this.state.sessionReadingCount + counter.expected,
            currentSortingGroupRead,
            readsCount: counter && counter.read ? counter.read : 0,
            expectedCount: counter && counter.expected ? counter.expected : 0,
            inDecoding: false,
            reloadingCounter: false,
          })
          if (this.state.pricing && currentItem.attributes?.zpl) {
            await this.print(currentItem.attributes?.zpl)
            this.setState({ lastZPL: { code: currentItem.item.id || '', zpl: currentItem.attributes?.zpl } })
          }
        } else {
          this.setState({ inDecoding: false })
        }
      } else {
        this.setState({ inDecoding: false })
      }
    } catch (error) {
      this.setState({ inDecoding: false })
      showToastError(error, __(T.error.error), this.isModal)
    }
  }

  print = async (zpl: string) => {
    try {
      const operationAttributes = this.operation.attributes as any
      const customIp = AppStore.defaultWorkstation?.attributes.ventilationBPSUSAPrinterIP
      const printerIp = !!customIp && customIp !== '' ? customIp : operationAttributes.printerIP
      if (!printerIp) throw new Error(__(T.error.missing_printer_id))
      if (zpl && zpl.length > 0) await Sortings.print(zpl, printerIp)
    } catch (error) {
      console.error(error)
    }
  }

  onPartialConfirm = async () => {
    if (
      await askUserConfirmation(
        __(T.confirm.confirm_operation_title, { operation: this.operation.description }),
        __(T.confirm.confirm_operation_text_partial_confirm)
      )
    ) {
      this.setState({ confirming: true })
      if (this.state.transactionDate !== '') this._partialConfirm()
      else this.openTransactionDateModal('partial-confirm')
    }
  }

  removeItem(decodedItem: DecodedItemSorting) {
    const items = OperationReadingProvider.removeItem(decodedItem, this.state.items)
    this.setState({ items })
  }

  _partialConfirm = async () => {
    const { sorting } = this.state
    try {
      const asyncJobs = hasAsyncConfirm(this.operation)
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }
      if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing workstation place')
      const confirmResult: any = await Sortings.partialConfirm(
        sorting!.id,
        this.operation.id,
        this.state.transactionDate,
        asyncJobs
      )
      if (asyncJobs) {
        if (confirmResult && confirmResult.asyncExecutionId) {
          this.setState({
            lastAsyncID: confirmResult.asyncExecutionId,
            asyncConfirmResult: confirmResult,
            asyncPartialConfirm: true,
          })
        } else throw new Error('Missing asyncExecutionId')
      } else {
        this._partialConfirmSuccess(confirmResult)
      }
    } catch (err) {
      this.setState({ confirming: false })
      showToastError(err, __(T.error.error), this.isModal)
    }
  }

  _partialConfirmSuccess = (confirmResult: any) => {
    if (confirmResult.confirmationError) throw new Error(confirmResult.confirmationError)
    if (confirmResult.confirmationWarning)
      showToast({
        title: __(T.misc.warning),
        description: confirmResult.confirmationWarning,
        status: 'warning',
      })
    this.setState({ confirming: false })
    showToast({
      title: __(T.misc.success),
      description: __(T.messages.generic_success, { code: this.operation.description }),
      status: 'success',
    })
  }

  goBack = () => {
    if (this.formSchema.length) {
      Router.navigate('/sorting/:opCode', { opCode: this.operation.code })
    } else {
      Router.navigate('/')
    }
  }

  onConfirm = async () => {
    if (
      await askUserConfirmation(
        __(T.confirm.confirm_operation_title, { operation: this.operation.description }),
        __(T.confirm.confirm_operation_text_irreversible)
      )
    ) {
      this.setState({ confirming: true })
      if (this.state.transactionDate !== '') this._confirm()
      else this.openTransactionDateModal('confirm')
    }
  }

  _confirm = async () => {
    const { items, formData, sorting } = this.state
    const asyncJobs = hasAsyncConfirm(this.operation)
    try {
      if (this.antennaRef && this.antennaRef.current) {
        await this.antennaRef.current.stopReader()
      }
      const confirmData = getDataFromSchema(formData, this.formSchema)
      await SortingExtensions.beforeConfirm(this.operation, confirmData, items)
      confirmData.attributes.transactionDate = this.state.transactionDate
      if (!AppStore.defaultWorkstation?.placeId) throw new Error('Missing workstation place')
      const confirmResult = await Sortings.close(
        {
          ...confirmData,
          operationId: this.operation.id,
          sortingId: sorting!.id,
        },
        asyncJobs
      )
      if (asyncJobs) {
        if (confirmResult && confirmResult.asyncExecutionId) {
          this.setState({
            lastAsyncID: confirmResult.asyncExecutionId,
            asyncConfirmResult: confirmResult,
            asyncPartialConfirm: false,
          })
        } else throw new Error('Missing asyncExecutionId')
      } else {
        await SortingExtensions.afterConfirm(this.operation, confirmData, confirmResult)
        this.setState({ confirming: false })
        showToast({
          title: __(T.misc.success),
          description: __(T.messages.generic_success, { code: this.operation.description }),
          status: 'success',
        })
        this.goBack()
      }
    } catch (err) {
      this.setState({ confirming: false })
      showToastError(err, __(T.error.error), this.isModal)
    }
  }

  async delete() {
    if (
      await askUserConfirmation(
        __(T.confirm.confirm_delete_operation_title, { operation: this.operation.description }),
        __(T.confirm.confirm_delete_operation)
      )
    ) {
      await Sortings.delete({ sortingId: this.state.sorting!.id, operationId: this.operation.id })
      //return to list
      this.goBack()
    }
  }

  itemIsOk(item: DecodedItemSorting) {
    return item.item?.status !== 'error' && !item.statuses.includes('ALREADY_READ')
  }

  getInitMessage = () => {
    const { inError } = this.state
    if (inError) {
      return __(T.messages.sorting_error_zones)
    } else {
      return __(RfidReader.isReading() ? T.messages.waiting_for_product : T.messages.press_start_to_read)
    }
  }

  renderCounterIcon = (type) => {
    if (type === 2)
      return (
        <IconMissing>
          <Icons.Minus />
        </IconMissing>
      )
    else if (type === 3)
      return (
        <IconUnexpected>
          <Icons.Close />
        </IconUnexpected>
      )
    else if (type === 4)
      return (
        <Box mr={10}>
          <Icons.Question />
        </Box>
      )
    return (
      <IconCheck>
        <Icons.CheckFilled />
      </IconCheck>
    )
  }

  renderCounter = (label, icon, counter, flex, mr) => {
    const { reloadingCounter } = this.state
    return (
      <Counter flex={flex} mr={mr}>
        <Box row mb={10}>
          {this.renderCounterIcon(icon)}
          <CounterTitle>{label}</CounterTitle>
        </Box>
        <CounterBody flex center>
          {!reloadingCounter ? (
            <Text style={{ fontSize: 60 }}>{counter}</Text>
          ) : (
            <Spinner thickness="5px" speed="0.65s" color="#454545" size="xl" />
          )}
        </CounterBody>
      </Counter>
    )
  }

  // Stati di errore
  getErrorMessage = (isOk) => {
    const { currentItem, currentSortingGroupRead, currentTag } = this.state
    let errorMessage
    if (!isOk) {
      if (currentItem?.statuses.includes('ALREADY_READ')) {
        errorMessage = __(T.error.sorting_already_read, {
          serial: getSerial(currentItem.item),
          group: currentSortingGroupRead?.assignedEntry.sortingGroup.code,
        })
      } else if (currentItem?.statuses.includes('SORTING_GROUP_NOT_FOUND')) {
        errorMessage = __(T.error.sorting_no_group)
      } else if (currentItem?.statuses.includes('NOT_IN_ZONE')) {
        errorMessage = __(T.error.sorting_not_in_zone, {
          serial: getSerial(currentItem.item),
          zone: currentItem.item?.zone?.description,
        })
      } else if (currentItem?.statuses.includes('UNEXPECTED')) {
        errorMessage = __(T.error.sorting_unexpected)
      }
    }
    return errorMessage
  }

  onChangePricing = () => {
    this.setState({ pricing: !this.state.pricing })
  }
  getHeaderRight = () => {
    const { originZones, originZoneId, pricing, sseStatus } = this.state
    const pricingEnabled = this.operation.pricingEnabled
    const addOriginZones = this.operation.enableOriginZone && originZones.length > 0
    if (addOriginZones && !pricingEnabled) {
      return (
        <>
          <SortingHeader onChange={this.changeZone} zones={originZones} selected={originZoneId} />
          <Box ml={5} mr={5}>
            <Button
              title={__(T.misc.transaction_date)}
              onClick={() => this.openTransactionDateModal('none')}
              variant="secondary"
            />
          </Box>
          {hasAsyncConfirm(this.operation) && <Stoplight active={sseStatus} />}
        </>
      )
    } else if (!addOriginZones && pricingEnabled) {
      return (
        <>
          <ToggleField label={__(T.misc.pricing)} onChange={() => this.onChangePricing()} checked={pricing} />
          <Box ml={5} mr={5}>
            <Button
              title={__(T.misc.transaction_date)}
              onClick={() => this.openTransactionDateModal('none')}
              variant="secondary"
            />
          </Box>
          {hasAsyncConfirm(this.operation) && <Stoplight active={sseStatus} />}
        </>
      )
    } else if (addOriginZones && pricingEnabled) {
      return (
        <>
          <ToggleField mr={15} label={__(T.misc.pricing)} onChange={() => this.onChangePricing()} checked={pricing} />
          <SortingHeader onChange={this.changeZone} zones={originZones} selected={originZoneId} />
          <Box ml={5} mr={5}>
            <Button
              title={__(T.misc.transaction_date)}
              onClick={() => this.openTransactionDateModal('none')}
              variant="secondary"
            />
          </Box>
          {hasAsyncConfirm(this.operation) && <Stoplight active={sseStatus} />}
        </>
      )
    }
    return undefined
  }

  openTransactionDateModal = async (action: 'none' | 'confirm' | 'partial-confirm') => {
    const performAction = async () => {
      closeModal('transaction-date-modal')
      if (action === 'confirm') this._confirm()
      if (action === 'partial-confirm') this._partialConfirm()
    }
    const onClose = () => {
      this.setState({ confirming: false })
      closeModal('transaction-date-modal')
    }
    const currentSelected = this.state.transactionDate !== '' ? this.state.transactionDate : new Date().toISOString()
    this.setState({ transactionDate: currentSelected })
    openModal({
      id: 'transaction-date-modal',
      modal: (
        <Modal onClose={() => {}} visible title={__(T.misc.select_transaction_date)} size="xl">
          <Icons.Close style={{ position: 'absolute', top: 10, right: 10 }} onClick={onClose} />
          <DatePicker
            selected={new Date(currentSelected)}
            onChange={(date: Date) => this.setState({ transactionDate: date.toISOString() || '' })}
          />
          <Spacer />
          <Button title="Confirm" onClick={performAction} style={{ width: '100%' }} />
        </Modal>
      ),
    })
  }

  render() {
    const {
      items,
      loading,
      inDecoding,
      currentItem,
      currentSortingGroupRead,
      sorting,
      readsCount,
      expectedCount,
      inError,
      originZoneId,
      reloadingCounter,
      confirming,
      ignoredCount,
    } = this.state
    const missing = expectedCount - readsCount > 0 ? expectedCount - readsCount : 0
    const unexpected = items.filter((itm) => itm.item?.status === 'error').length

    // Stati di errore
    const isOk = currentItem ? this.itemIsOk(currentItem) : false
    const errorMessage = this.getErrorMessage(isOk)
    return (
      <Page
        title={this.operation.description}
        onBackPress={() => this.goBack()}
        loading={loading}
        forceDetails="top"
        headerRight={this.getHeaderRight()}
        enableEmulation
      >
        <Page.Content notBoxed overflowY="visible">
          <Main flex row>
            <>
              {!sorting && (
                <Box flex center>
                  <Spinner thickness="5px" speed="0.65s" color="#454545" size="xl" />
                </Box>
              )}
              {sorting && !currentItem && !inDecoding && (
                <EmptyListMessage center bg={inError ? '#FF8474' : '#FFFFFF'}>
                  {this.getInitMessage()}
                </EmptyListMessage>
              )}
              {sorting && currentItem && !inDecoding && (
                <ProductBox ok={isOk} width="100%" row>
                  {isOk && (
                    <>
                      <SortingGroupLabel currentSortingGroupRead={currentSortingGroupRead} />
                      <SortingProductInfo
                        operation={this.operation}
                        currentItem={currentItem}
                        currentSortingGroupRead={currentSortingGroupRead}
                      />
                    </>
                  )}
                  {!isOk && (
                    <ProductTitle flex justify="center" ph={50} ok={false}>
                      {errorMessage}
                    </ProductTitle>
                  )}
                </ProductBox>
              )}
              {inDecoding && (
                <Box center row flex>
                  <Spinner thickness="5px" speed="0.65s" color="#e0e0e0" size="xl" />
                </Box>
              )}
            </>
          </Main>
          {sorting && (
            <>
              <Spacer />
              <Box row>
                {this.renderCounter(
                  __(T.misc.read) + '/' + __(T.misc.expected),
                  1,
                  readsCount + '/' + expectedCount,
                  1.6,
                  12
                )}
                {this.renderCounter(__(T.misc.missing), 2, missing, 1, 12)}
                {this.renderCounter(__(T.misc.unexpected), 3, unexpected, 1, 12)}
                {this.renderCounter(__(T.misc.ignored_plural), 4, ignoredCount, 1, 0)}
              </Box>
              <Spacer />
              <Flex alignItems="center">
                {!inError && !reloadingCounter && (
                  <Box>
                    <AntennaButton
                      ref={this.antennaRef}
                      decodeFunction={Items.batchDecode}
                      onItemDecoded={this.onItemDecoded}
                      style={{ width: 300 }}
                      hideClear
                      showPendingTags={false}
                    />
                  </Box>
                )}
                <Box flex="auto" />
                {this.operation.showDeleteButton && (
                  <Box mr={12}>
                    <Button
                      title={__(T.misc.delete)}
                      onClick={() => {
                        this.delete()
                        return true
                      }}
                      variant="secondary"
                      padding="30px 25px"
                    />
                  </Box>
                )}
                <Box mr={this.operation.showCloseButton ? 5 : 0}>
                  <DetailsButton
                    sortingId={sorting.id}
                    originZoneId={this.operation.enableOriginZone ? originZoneId : ''}
                    lastZPL={this.state.lastZPL}
                    operation={this.operation}
                  />
                </Box>
                {this.operation.showCloseButton && (
                  <Box>
                    <Button
                      loading={confirming}
                      padding="30px 25px"
                      title={__(T.misc.confirm)}
                      onClick={this.onConfirm}
                    />
                  </Box>
                )}
                <Box ml={5} mr={5}>
                  <Button
                    loading={confirming}
                    padding="30px 25px"
                    title={__(T.misc.partial_confirm)}
                    onClick={this.onPartialConfirm}
                  />
                </Box>
              </Flex>
            </>
          )}
        </Page.Content>
      </Page>
    )
  }
}

type ModalLevels = 'group' | 'entry' | 'read'

const DetailsButton: React.FC<{
  sortingId: string
  originZoneId: string
  lastZPL?: { code: string; zpl: string }
  operation: any
  lastReaded?: string
}> = ({ sortingId, originZoneId, operation, lastReaded, lastZPL }) => {
  const [level, setLevel] = useState<ModalLevels>('group')
  const [sortingGroups, setSortingGroups] = useState<SortingGroup[]>()
  const [sortingGroup, setSortingGroup] = useState<SortingGroup>()
  const [sortingGroupEntries, setSortingGroupEntries] = useState<SortingGroupEntry[]>()
  const [sortingGroupEntry, setSortingGroupEntry] = useState<SortingGroupEntry>()
  const [sortingGroupReads, setSortingGroupReads] = useState<SortingGroupRead[]>()
  const [isOpen, setIsOpen] = useState(false)
  const prevIsOpen = usePrevState(isOpen)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)

  const levelGroupStructure = [
    { key: 'priority', value: 'priority', label: __(T.misc.priority), width: '18%' },
    { key: 'group', value: 'description', secondaryValue: 'code', label: __(T.misc.group), width: '33%' },
    {
      key: 'required_quantity',
      customRender: (group) => <ModalLabel style={{ backgroundColor: '#ededed' }}>{group.expectedCount}</ModalLabel>,
      label: __(T.misc.required_quantity),
      width: '19%',
      align: 'center',
    },
    {
      key: 'sorted_quantity',
      customRender: (group) => (
        <ModalLabel
          style={{
            backgroundColor: group.readsCount < group.expectedCount ? '#ff8474' : '#74ff92',
          }}
        >
          {group.readsCount}
        </ModalLabel>
      ),
      label: __(T.misc.sorted_quantity),
      width: '19%',
      align: 'center',
    },
    {
      key: 'details',
      customRender: (group) => {
        return lastReaded === group.epc ? (
          <ButtonDetails>
            <Button
              onClick={() => {
                setLevel('entry')
                setSortingGroup(group)
                resetSearch()
              }}
              size="small"
              circle
              variant="secondary"
              padding="0px"
            >
              <Icons.Dots width={24} height={24} />
            </Button>
          </ButtonDetails>
        ) : (
          <></>
        )
      },
      width: '11.4%',
    },
  ]

  const entryProductLabel = () => {
    return __(T.misc.barcode_bc)
    return __(T.misc.upc)
  }

  const entryProductData = (entry) => {
    if (entry.product.attributes && entry.product.attributes.barcode) {
      return entry.product.attributes.barcode.value && entry.product.attributes.barcode.value !== ''
        ? entry.product.attributes.barcode.value
        : '---'
    }
    return entry.product.code || '---'
  }

  const printZPL = async (zpl: string) => {
    try {
      const operationAttributes = operation.attributes as any
      const customIp = AppStore.defaultWorkstation?.attributes.ventilationBPSUSAPrinterIP
      const printerIp = !!customIp && customIp !== '' ? customIp : operationAttributes.printerIP
      if (!printerIp) throw new Error(__(T.error.missing_printer_id))
      if (zpl && zpl.length > 0) await Sortings.print(zpl, printerIp)
    } catch (error) {
      console.error(error)
    }
  }

  const levelEntryStructure = [
    {
      key: 'productReference',
      label: __(T.misc.barcode_bc),
      width: '20%',
      customLabel: () => <div>{entryProductLabel()}</div>,
      customRender: (entry) => <ModalValue>{entryProductData(entry)}</ModalValue>,
    },
    {
      key: 'description',
      value: 'product.description',
      label: __(T.misc.description),
      width: '24%',
    },
    {
      key: 'required_quantity',
      customRender: (entry) => <ModalLabel style={{ backgroundColor: '#ededed' }}>{entry.expectedQuantity}</ModalLabel>,
      label: __(T.misc.required_quantity),
      width: '15%',
      align: 'center',
    },
    {
      key: 'sorted_quantity',
      customRender: (entry) => (
        <ModalLabel
          style={{
            backgroundColor: entry.assignedQuantity < entry.expectedQuantity ? '#ff8474' : '#74ff92',
          }}
        >
          {entry.assignedQuantity}
        </ModalLabel>
      ),
      label: __(T.misc.sorted_quantity),
      width: '15%',
      align: 'center',
    },
    {
      key: 'missing_quantity',
      customRender: (entry) => (
        <ModalLabel
          style={{
            backgroundColor: entry.assignedQuantity < entry.expectedQuantity ? '#ff8474' : '#74ff92',
          }}
        >
          {entry.expectedQuantity - entry.assignedQuantity}
        </ModalLabel>
      ),
      label: __(T.misc.missing_quantity),
      width: '15%',
      align: 'center',
    },
    {
      key: 'details',
      customRender: (entry) => (
        <ButtonDetails>
          <Button
            onClick={() => {
              setLevel('read')
              setSortingGroupEntry(entry)
              resetSearch()
            }}
            size="small"
            circle
            variant="secondary"
            padding="0px"
            //disabled={entry.assignedQuantity === 0}
          >
            <Icons.Dots width={24} height={24} />
          </Button>
        </ButtonDetails>
      ),
      width: '11%',
    },
  ]

  const levelReadStructure = [
    {
      key: 'serial',
      customValue: (read) => getSerial(read.item),
      label: __(T.misc.serial),
      width: '18%',
    },
    {
      key: 'epc',
      customValue: (read) => getEpc(read.item),
      label: 'EPC',
      width: '18%',
    },
    {
      key: 'zone',
      value: 'item.zone.description',
      label: __(T.misc.zone),
      width: '18%',
    },
    {
      key: 'sortingDate',
      customValue: (read) => format(new Date(read.creationDate), 'MM/dd/yyyy HH:mm:ss'),
      label: __(T.misc.sorted_date),
      width: '18%',
    },
    { key: 'user', value: 'creationUser.username', label: __(T.misc.user), width: '18%' },
    {
      key: 'print',
      customRender: (entry) => {
        if (lastZPL && lastZPL.code === entry.item.id)
          return (
            <Box
              center
              style={{ fontWeight: 'bold', fontSize: 14, width: '100%', borderRadius: 5 }}
              onClick={() => {
                if (lastZPL.zpl) printZPL(lastZPL.zpl)
              }}
            >
              <Icons.Print height={25} width={25} />
            </Box>
          )
      },
    },
  ]

  const closeModalLevel = () => {
    setLevel('group')
    resetSearch()
    setIsOpen(false)
    setSortingGroups(undefined)
  }

  useEffect(() => {
    if (isOpen === true && prevIsOpen === false) {
      const fetch = async () => {
        setLoading(true)
        const newSortingGroups = (
          await SortingGroups.search<SortingGroup>({ size: 99999, sortingId, originZoneFilter: originZoneId })
        ).content
        setSortingGroups(newSortingGroups)
        setLoading(false)
      }
      fetch()
    }
  }, [isOpen, prevIsOpen, sortingId, originZoneId])

  useEffect(() => {
    if (level === 'entry' && sortingGroup?.id) {
      const fetch = async () => {
        setLoading(true)
        setSortingGroupEntries(
          (
            await SortingGroupEntries.search<SortingGroupEntry>({
              size: 99999,
              sortingGroupIds: [sortingGroup?.id],
              originZoneIds: [originZoneId],
            })
          ).content
        )
        setLoading(false)
      }
      fetch()
    }
  }, [level, sortingGroup?.id, originZoneId])

  useEffect(() => {
    if (level === 'read' && sortingGroupEntry?.id) {
      const fetch = async () => {
        setLoading(true)
        setSortingGroupReads(
          (
            await SortingGroupReads.search<SortingGroupRead>({
              size: 99999,
              sortingGroupEntryIds: [sortingGroupEntry?.id],
            })
          ).content
        )
        setLoading(false)
      }
      fetch()
    }
  }, [level, sortingGroupEntry?.id])

  sortingGroups?.sort((a, b) => a.priority - b.priority)
  sortingGroupEntries?.sort((a, b) =>
    a.assignedQuantity - a.expectedQuantity < b.assignedQuantity - b.expectedQuantity ? -1 : 1
  )

  const getSortingGroupLabel = () => {
    if (sortingGroup) {
      if (sortingGroup.description && sortingGroup.description !== null && sortingGroup.description !== '') {
        return sortingGroup.description
      }
      return sortingGroup.code
    }
    return '---'
  }

  const resetSearch = () => setSearch('')
  return (
    <>
      <Button padding="30px 25px" title={__(T.misc.detail)} onClick={() => setIsOpen(true)} />
      <Modal visible={isOpen} onClose={closeModalLevel} size="5xl" isCentered>
        <Box height="80vh">
          <ModalTitle mb="1em">
            {level === 'group' && __(T.titles.sorting_details)}
            {level === 'entry' && (
              <ModalBackButton
                toLevel="group"
                setLevel={setLevel}
                resetSearch={resetSearch}
                title={getSortingGroupLabel()}
              />
            )}
            {level === 'read' && (
              <ModalBackButton
                toLevel="entry"
                setLevel={setLevel}
                resetSearch={resetSearch}
                title={`${sortingGroupEntry?.sortingGroup.code} - ${sortingGroupEntry?.product.attributes?.barcode?.value}`}
              />
            )}
          </ModalTitle>
          {loading && (
            <Box flex center vcenter>
              <Spinner thickness="5px" speed="0.65s" color="#e0e0e0" size="xl" />
            </Box>
          )}
          {!loading && (
            <Box style={{ overflow: 'auto' }} flex>
              {level === 'group' && (
                <>
                  <Box width="50%" ml={5} mt={5} mb={15}>
                    <Input onChange={(value) => setSearch(value)} placeholder={__(T.misc.search)} startFocus />
                  </Box>
                  <Table
                    loading={!sortingGroups}
                    structure={levelGroupStructure}
                    data={sortingGroups?.filter((group) =>
                      search === '' ? true : group.code.toLowerCase().includes(search.toLowerCase())
                    )}
                  />
                </>
              )}
              {level === 'entry' && (
                <>
                  <Box width="50%" ml={5} mt={5} mb={15}>
                    <Input onChange={(value) => setSearch(value)} placeholder={__(T.misc.search)} startFocus />
                  </Box>
                  <Table
                    loading={!sortingGroupEntries}
                    structure={levelEntryStructure}
                    data={sortingGroupEntries?.filter((entry) =>
                      search === ''
                        ? true
                        : entry.product.attributes?.barcode?.value?.toLowerCase().includes(search.toLowerCase())
                    )}
                  />
                </>
              )}
              {level === 'read' && (
                <>
                  <Box width="50%" ml={5} mt={5} mb={15}>
                    <Input onChange={(value) => setSearch(value)} placeholder={__(T.misc.search)} startFocus />
                  </Box>
                  <Table
                    loading={!sortingGroupReads}
                    structure={levelReadStructure}
                    data={sortingGroupReads?.filter((read) =>
                      search === '' ? true : getSerial(read.item).toLowerCase().includes(search.toLowerCase())
                    )}
                  />
                </>
              )}
            </Box>
          )}
        </Box>
      </Modal>
    </>
  )
}

const ModalBackButton: React.FC<{
  toLevel: ModalLevels
  setLevel: (level: ModalLevels) => void
  resetSearch: () => void
  title?: string
}> = ({ toLevel, setLevel, resetSearch, title }) => (
  <Box flex row>
    <Button
      onClick={() => {
        resetSearch()
        setLevel(toLevel)
      }}
      circle
      size="small"
      padding="0px 10px"
    >
      <Icons.LeftArrow />
    </Button>
    <ModalTitle ml={10}>{title}</ModalTitle>
  </Box>
)

const Main = styled(Box)`
  background-color: ${({ theme }) => theme.background2};
  border-radius: 10px;
`
const ProductBox = styled(Box)<{ ok: boolean }>`
  background-color: ${({ ok }) => (ok ? '#75eba8' : '#ff8474')};
  border-radius: 10px;
`
const ProductTitle = styled(Box)<{ ok: boolean }>`
  font-weight: bold;
  font-size: ${({ ok }) => (ok ? 'clamp(36px, 5vw, 100px)' : '36px')};
  color: #333333;
  border-right: ${({ ok }) => (ok ? '1px' : '0')} solid #5dd691;
`
const Counter = styled(Box)`
  background-color: #fff;
  height: 184px;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 0px 8px 0px #00000026;
`
const CounterTitle = styled.div`
  font-weight: bold;
`
const CounterBody = styled(Box)`
  font-weight: bold;
  background-color: #e9e9e9;
  font-size: clamp(32px, 5vw, 80px);
  padding: 0 10px;
`
const IconCheck = styled.div`
  margin-right: 10px;
  svg * {
    fill: #75eba8;
  }
`
const IconMissing = styled.div`
  margin-right: 10px;
  svg * {
    fill: #ebbc75;
  }
`
const IconUnexpected = styled.div`
  margin-right: 10px;
  svg * {
    fill: #ff8474;
  }
`
const ModalTitle = styled(Box)`
  font-size: 26px;
  font-weight: bold;
`
const ModalLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  padding: 0.5rem 1rem;
  align-self: center;
`
const ButtonDetails = styled.div`
  text-align: center;
  align-self: center;
`
const ModalValue = styled.div`
  font-size: 14px;
`
