import { Component } from 'react'
import styled from '@emotion/styled'
import { Box } from 'stylewhere/components'
import { SortingGroupRead } from 'stylewhere/api'

interface Props {
  currentSortingGroupRead?: SortingGroupRead
}

export class SortingGroupLabel extends Component<Props> {
  gerSortingLabel = () => {
    const { currentSortingGroupRead } = this.props
    if (
      currentSortingGroupRead &&
      currentSortingGroupRead.assignedEntry &&
      currentSortingGroupRead.assignedEntry.sortingGroup
    ) {
      if (
        currentSortingGroupRead.assignedEntry.sortingGroup.description &&
        currentSortingGroupRead.assignedEntry.sortingGroup.description !== '' &&
        currentSortingGroupRead.assignedEntry.sortingGroup.description !== null
      )
        return currentSortingGroupRead.assignedEntry.sortingGroup.description
      return currentSortingGroupRead.assignedEntry.sortingGroup.code
    }
    return '---'
  }

  render() {
    return (
      <ProductTitle flex={1.5} justify="center" ok pl={50} pr={25}>
        {this.gerSortingLabel()}
      </ProductTitle>
    )
  }
}

const ProductTitle = styled(Box)<{ ok: boolean }>`
  font-weight: bold;
  font-size: ${({ ok }) => (ok ? 'clamp(36px, 5vw, 100px)' : '36px')};
  color: #333333;
  border-right: ${({ ok }) => (ok ? '1px' : '0')} solid #5dd691;
`
